import React, { Component } from "react";
import Icon from "../../../assets/images/icons/world.svg";
import "../../../assets/css/custom.css";
import { serverResponse } from "../../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../../settings/gaConfig";
import { isMobile } from "react-device-detect";
import { gAInitializer } from "../../../helper/utility";

export default class WorldMarker extends Component {
  componentDidMount() {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
  }

  handleClick = () => {
    window.open(this.props.markerInfo.data, "_blank");
  };

  render() {
    return (
      <div
        className={"marker " + serverResponse.config.iconAnimation}
        type="world"
        style={{ display: this.props.info.hidden_price ? "none" : "" }}
      >
        <img
          id={"world-" + this.props.info.item_id}
          src={Icon}
          className={
            serverResponse.config.largeIcon
              ? "icon-marker-large"
              : "icon-marker"
          }
          style={{ display: isMobile ? "none" : "" }}
          alt={this.props.markerInfo.title}
          onClick={() => {
            if (serverResponse.config.ga_active) {
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.open_product_link,
                label: String(this.props.info.item_id)
              });
            }
            this.handleClick();
          }}
        />
        {isMobile && (
          <img
            src={Icon}
            className="icon-marker"
            alt={this.props.markerInfo.title}
            onClick={() => {
              this.props.navMarkers(this.props.info.pam ? "world" : "plus");
            }}
          />
        )}
      </div>
    );
  }
}
