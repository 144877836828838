import React from "react"
import PlusMarkerIndex from "./PlusMarkerIndex";
import { getCustomActions } from '../../../helper/utility'

const PlusMarker = (props) => {
  const type = 'PlusMarker' + getCustomActions().component;
  const ComponentToRender = PlusMarkerIndex[type];
  return <ComponentToRender info={props.info} markerInfo={props.element} leafletSwipe={props.leafletSwipe} navMarkers={props.navMarkers} setSwipe={props.setSwipe} />;
}

export default PlusMarker