import React from "react";
import { useState } from "react";
import { Modal, ModalHeader, Button } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ClearChart from "../../assets/images/icons/clear_chart.png";
import CartRemove from "../../assets/images/icons/Cart_delete.svg";
import { serverResponse } from "../../util/fakeServer";
import { styles } from "./RemoveAllBtn.style";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";

const RemoveAllBtn = (props) => {
  const [isOpen, toggleOpen] = useState(false);
  const [tooltip, toggleTooltip] = useState(false);

  const primary_color = serverResponse.config.primary_color;
  const secondary_color = serverResponse.config.secondary_color;
  let iconClearCart = ClearChart;
  let label = "lista";

  if (serverResponse.config.send_grocery_list_to_market) {
    iconClearCart = CartRemove;
    label = "carrello";
  }

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => toggleTooltip(true)}
      onMouseLeave={() => toggleTooltip(false)}
    >
      {tooltip && (
        <div style={styles.tooltip(primary_color)}>
          <p>{language[defaultLanguage].svuota}</p>
        </div>
      )}
      <Button
        id="removeAllBtn"
        outline
        style={{
          border: "none"
        }}
        onClick={() => {
          if (props.active) {
            toggleOpen(!isOpen);
            toggleTooltip(false);
          }
        }}
      >
        <img
          alt="Svuota Carrello"
          id="btnDeleteAll"
          src={iconClearCart}
          style={styles.icon}
        />
      </Button>
      <Modal isOpen={isOpen} centered>
        <ModalHeader>
          {language[defaultLanguage].svuota} {label}
        </ModalHeader>
        <ModalBody>
          {language[defaultLanguage].sei_sicuro_svuota_carrello}?
        </ModalBody>
        <ModalFooter>
          <button
            className="btn"
            style={styles.abort(secondary_color)}
            onClick={() => toggleOpen(!isOpen)}
          >
            {language[defaultLanguage].annulla}
          </button>
          <button
            className="btn"
            style={styles.confirm}
            onClick={() => {
              props.deleteAll();
              toggleOpen(!isOpen);
            }}
          >
            {language[defaultLanguage].conferma}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RemoveAllBtn;
