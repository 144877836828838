import React from "react";
import { serverResponse } from "../../util/fakeServer";
import { iconAppNameNegative } from "helper/constant";
import { styles } from "./carouselLoader.style";

export default function CarouselLoader() {
  const logo = serverResponse.config.logo_full;
  return (
    <div
      style={styles.backgroundColorLoader(
        serverResponse.config.background_color
      )}
      className="carouselLoader"
    >
      <img
        alt="loader..."
        className="imgLoader"
        src={logo !== null && logo !== undefined ? logo : iconAppNameNegative}
      />
    </div>
  );
}
