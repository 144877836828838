import React, { useRef, useState, useEffect } from "react";
import CustomProduct from "./CustomProduct";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { H_COMPONENTS } from "../../helper/constant";
import { isMobileSafari } from "react-device-detect";
import ReactDOM from "react-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import Loader from "../common/Loader";
const CustomSlide = (props) => {
  const [loadingImages, setLoadingImages] = useState(true);
  const listProducts = props.products.map((element, key) => (
    <CustomProduct
      zoom={props.zoom}
      disableSwipe={props.disableSwipe}
      enableSwipe={props.enableSwipe}
      key={key}
      info={element}
      leafletSwipe={props.leafletSwipe}
      isSwipeDisabed={props.isSwipeDisabed}
      setSwipe={props.setSwipe}
    />
  ));
  const imageRef = useRef(null);
  var interval = 0;
  const isImageLoad = () => {
    var element = ReactDOM.findDOMNode(imageRef.current);
    if (element !== null && element.offsetWidth > 0) {
      clearInterval(interval);
      setLoadingImages(false);
    }
  };
  useEffect(() => {
    interval = setInterval(isImageLoad, 500);
  }, []);
  const safariDetectMobile = isMobileSafari ? 30 : 0;
  return (
    <div className="d-flex p-0 flex-column justify-content-center align-items-center bordo">
      {loadingImages && <Loader single={props.single} />}
      <div style={{ position: "relative" }}>
        <div className={`shadow-${props.shadow}`}></div>
        <LazyLoadImage
          ref={imageRef}
          alt=""
          src={props.imagePath + "?v=" + serverResponse.config.version}
          placeholderSrc={
            props.imageThumbPath + "?v=" + serverResponse.config.version
          }
          visibleByDefault={true}
          className="img-fluid"
          style={{
            maxHeight: serverResponse.config.adsense
              ? `calc(100vh - ${H_COMPONENTS.header +
                  H_COMPONENTS.adsense +
                  H_COMPONENTS.pager +
                  safariDetectMobile}px)`
              : `calc(100vh - ${H_COMPONENTS.header +
                  H_COMPONENTS.pager +
                  safariDetectMobile}px)`
          }}
        />
        {listProducts}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};
export default connect(mapStateToProps)(CustomSlide);