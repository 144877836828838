export const styles = {
    available: (color, isMobile) => {
        return {
            color: color,
            marginLeft: "5px",
            fontSize: "12px",
            lineHeight: isMobile ? "20px" : "20px"
        }
    },
    info: (color) => {
        return {
            color: color,
            marginLeft: "5px",
            cursor: "pointer",
            fontSize:"14px"
        }
    },
    prices: (color) => {
        return {
            borderRadius: "0.25rem",
            backgroundColor: "white",
            border: "1px solid " + color,
            position: "absolute",
            padding: ".2rem .75rem",
            fontSize: "15px",
            zIndex: 1
        }
    }
}