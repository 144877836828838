export const styles = {
  img: {
    width: "100px",
    height: "100px"
  },
  field: {
    fontSize: "13px"
  },
  addCartContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
};
