import React, { Component } from "react";
import Icon from "../../../assets/images/icons/world.svg";
import "../../../assets/css/custom.css";
import { serverResponse } from "../../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../../settings/gaConfig";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../../redux/carousel/action";
import { gAInitializer, getValue } from "../../../helper/utility";

class InternalLinkMarker extends Component {
  componentDidMount() {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
  }

  handleClick = () => {
    this.props.setIndexCarousel(
      getValue(this.props.markerInfo.data.page_number)
    );
    // this.props.goToPage(this.props.markerInfo.data.page_number);
  };

  render() {
    return (
      <div
        className={"marker " + serverResponse.config.iconAnimation}
        type="world"
        style={{ display: this.props.info.hidden_price ? "none" : "" }}
      >
        <img
          title={
            this.props.markerInfo.data.tooltip !== undefined
              ? this.props.markerInfo.data.tooltip
              : ""
          }
          id={"world-" + this.props.info.item_id}
          src={Icon}
          className={
            serverResponse.config.largeIcon
              ? "icon-marker-large"
              : "icon-marker"
          }
          style={{
            display: !this.props.markerInfo.data.show_icon ? "none" : ""
          }}
          alt=""
          onClick={() => {
            if (serverResponse.config.ga_active) {
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.open_product_link,
                label: String(this.props.info.item_id)
              });
            }
            this.handleClick();
          }}
        />
      </div>
    );
  }
}

export default connect(null, { setIndexCarousel })(InternalLinkMarker);
