import Layout from "layouts/DashboardLayout.jsx";

import MyGroceryList from '../views/MyGroceryList';
import PrintGroceryList from '../views/PrintGroceryList';
import PDFGroceryList from '../views/PDFGroceryList';
import PdfDownload from "../components/share/PdfDownload";

const indexRoutes = [
  { path: "/myGroceryList/:id", component: MyGroceryList},
  { path: "/printGroceryList", component: PrintGroceryList},
  { path: "/PDFGroceryList", component: PDFGroceryList},
  { path: '/pdfDownload/:id', component: PdfDownload},
  { path: "/", component: Layout }
];

export default indexRoutes;