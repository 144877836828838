const actions = {

    ADD_PRODUCT: 'ADD_PRODUCT',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    CHANGE_QUANTITY: 'CHANGE_QUANTITY',
    CHECK_PRODUCT: 'CHECK_PRODUCT',
    DELETE_ALL: 'DELETE_ALL',

    addProduct: (data) => {
        return {
            type: actions.ADD_PRODUCT,
            newItem: data,
        }
    },

    changeQuantity: (data) => {
        return {
            type: actions.CHANGE_QUANTITY,
            quantity: data.quantity,
            item: data.item,

        }
    },

    checkProduct: (data) => {
        return {
            type: actions.CHECK_PRODUCT,
            item: data.item,
        }
    },

    deleteProduct: (data) => {
        return {
            type: actions.DELETE_PRODUCT,
            item: data.item,
        }
    },

    deleteAll: (data) => {
        return {
            type: actions.DELETE_ALL,
        }
    }

}

export default actions;