import actions from './actions';

const initState = {
    menuColorValue: 'red', 
}

export default function rootReducer(state = initState, action) {

    switch (action.type) {
        case actions.MENU_COLOR:
            return {
                ...state,
                menuColorValue: action.menuColor,
            }
        default: 
            return state 
    }
}