import React, { Component } from "react";
import Icon from "../../../assets/images/icons/play.svg";
import { Player } from "video-react";
import "../../../assets/css/video-react.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import HatChefIcon from "../../../assets/images/icons/hatchef.svg";
import SpecsIcon from "../../../assets/images/icons/specs.svg";
import PromoIcon from "../../../assets/images/icons/promo.svg";
import PlusIcon from "../../../assets/images/icons/plus.svg";
import WorldIcon from "../../../assets/images/icons/world.svg";
import InfoIcon from "../../../assets/images/icons/info.svg";

import "../../../assets/css/custom.css";
import Facebook from "../../../assets/images/icons/facebook.svg";
import Whatsapp from "../../../assets/images/icons/whatsapp.svg";
import { serverResponse } from "../../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../../settings/gaConfig";
import { isMobile, isIOS } from "react-device-detect";
import { connect } from "react-redux";
import { isModalOpen } from "../../../redux/carousel/action";
import { defaultLanguage, gAInitializer } from "../../../helper/utility";
import { language } from "../../../languageProvider/language/language";

const productUrl = serverResponse.config.shareFlyerURL;

const width = window.innerWidth;
const height = window.innerHeight;
const aspectRatio = width / height;

class PlayMarker extends Component {
  componentDidMount() {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
  }

  setModal = (swipe) => {
    //TODO
    //axios per l'incremento del counter di visualizzazioni della modal play
    /*
        if(this.state.modal === false){
            axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });
        }*/
    this.props.setSwipe(swipe);
    //this.props.leafletSwipe();
    this.setState({ modal: !this.state.modal });
  };

  shareOnFacebook = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_facebook,
        label: String(this.props.info.item_id)
      });
    }
    // let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
    // window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  shareOnWhatsapp = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_whatsapp,
        label: String(this.props.info.item_id)
      });
    }
    // let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
    // //window.location = "http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc);
    // window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
    // window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(page_url), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  };

  state = {
    modal: false
  };

  render() {
    const markers = this.props.info.markers.map((element, key) => {
      switch (element.type) {
        case "plus": {
          return (
            <img
              key={key}
              src={PlusIcon}
              className="icon-marker"
              alt="Info"
              onClick={() => {
                this.props.navMarkers("plus");
                this.setModal(false);
              }}
            />
          );
        }
        case "info": {
          return (
            <img
              key={key}
              src={InfoIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("info");
                this.setModal(false);
              }}
            />
          );
        }
        case "world": {
          return (
            <img
              key={key}
              src={WorldIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("world");
                this.setModal(false);
              }}
            />
          );
        }
        case "hat-chef": {
          return (
            <img
              key={key}
              src={HatChefIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("hatchef");
                this.setModal(false);
              }}
            />
          );
        }
        case "specs": {
          return (
            <img
              key={key}
              src={SpecsIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("specs");
                this.setModal(false);
              }}
            />
          );
        }
        case "promo": {
          return (
            <img
              key={key}
              src={PromoIcon}
              className="icon-marker-promo"
              alt="World"
              onClick={() => {
                this.props.navMarkers("promo");
                this.setModal(false);
              }}
            />
          );
        }
        case "play": {
          return null;
        }
        default:
          return null;
      }
    });

    var closeBtn = (
      <Button
        color="secondary"
        onClick={() => {
          if (this.props.zoom !== undefined) {
            this.props.zoom.reset();
          }
          this.setModal(true);
        }}
      >
        <i className="fas fa-arrow-left"></i>
        <strong> {language[defaultLanguage].indietro}</strong>
      </Button>
    );
    var w = serverResponse.config.large_video_icon ? "40px" : "25px";
    return (
      <div
        className={
          serverResponse.config.noIconAnimated ? "marker" : "marker vertical"
        }
        type="play"
        style={{ display: this.props.info.hidden_price ? "none" : "" }}
      >
        <img
          title={
            this.props.markerInfo.data.tooltip !== undefined
              ? this.props.markerInfo.data.tooltip
              : ""
          }
          id={"play-" + this.props.info.item_id}
          src={Icon}
          className={
            serverResponse.config.largeIcon
              ? "icon-marker-large"
              : "icon-marker"
          }
          style={{ width: w, display: isMobile ? "none" : "" }}
          alt="Play"
          onClick={() => {
            this.setModal(false);
            if (serverResponse.config.ga_active) {
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.open_product_video,
                label: String(this.props.info.item_id)
              });
            }
          }}
        />
        {isMobile && (
          <img
          id={"play-" + this.props.info.item_id}
            src={Icon}
            className="icon-marker"
            alt="Play"
            onClick={() => {
              if (this.props.info.type === "video") {
                document
                  .getElementById("play-" + this.props.info.item_id)
                  .click();
              }
              this.props.navMarkers("play");
              return;
              // this.props.navMarkers("plus");
            }}
          />
        )}
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.setModal(true)}
          size={aspectRatio > 1.6 ? "xl" : "md"}
          onOpened={() => {
            this.props.isModalOpen(true);
            if (this.props.disableSwipe !== undefined)
              this.props.disableSwipe();
          }}
          onClosed={() => {
            this.props.isModalOpen(false);
            if (this.props.enableSwipe !== undefined) this.props.enableSwipe();
          }}
        >
          <ModalHeader toggle={() => this.setModal(true)} close={closeBtn}>
            {language[defaultLanguage].video}
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ /*display: "flex", */ height: "30px" }}>
                <div className="markers-container" style={{ width: "100%" }}>
                  <div className="vertical">{markers}</div>
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Facebook}
                      className="share-icon"
                      style={{ marginLeft: "auto" }}
                      alt="facebook"
                      onClick={() => this.shareOnFacebook()}
                    />
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      onClick={() => this.shareOnWhatsapp()}
                    />
                  )}
                </div>
              </Col>
            </Row>
            {!this.props.markerInfo.data.open_modal &&
              this.props.markerInfo.data.video_type === "video_file" && (
                <Player
                  autoPlay={true}
                  playsInline
                  src={process.env.PUBLIC_URL + this.props.markerInfo.data.link}
                />
              )}
            {!this.props.markerInfo.data.open_modal &&
              this.props.markerInfo.data.video_type === "youtube" && (
                <div className="video-responsive" style={{ height: "50vh" }}>
                  <iframe
                    title={this.props.markerInfo.data.link}
                    width="100%"
                    height="100%"
                    src={
                      "https://www.youtube.com/embed/" +
                      this.props.markerInfo.data.link +
                      "?autoplay=1&mute=1"
                    }
                  />
                </div>
              )}
            {this.props.markerInfo.data.open_modal === true &&
              this.props.markerInfo.data.video_type === "video_file" && (
                <Player
                  autoPlay={true}
                  playsInline
                  src={process.env.PUBLIC_URL + this.props.markerInfo.data.link}
                />
              )}
            {this.props.markerInfo.data.open_modal === true &&
              this.props.markerInfo.data.video_type === "youtube" && (
                <div className="video-responsive" style={{ height: "50vh" }}>
                  <iframe
                    title={this.props.markerInfo.data.link}
                    width="100%"
                    height="100%"
                    src={
                      "https://www.youtube.com/embed/" +
                      this.props.markerInfo.data.link +
                      "?autoplay=1&mute=1"
                    }
                  />
                </div>
              )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (this.props.zoom !== undefined) {
                  this.props.zoom.reset();
                }
                this.setModal(true);
              }}
            >
              {language[defaultLanguage].chiudi}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { isModalOpen })(PlayMarker);
