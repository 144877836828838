import React, { useRef } from "react";
import { Button } from "reactstrap";
import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import { setIndexCarousel, isFromInput } from "../../redux/carousel/action";
import { getValue } from "../../helper/utility";
import { styles } from "./inputPage.style";

const InputPage = (props) => {
  const { carouselIndex } = props;

  const inputPage = useRef();

  function change(event) {
    let target = event.target;
    let input = parseInt(target.value, 10);
    if (event.keyCode === 13) {
      props.isFromInput(true);
      props.setIndexCarousel(getValue(input));
      target.value = "";
      inputPage.current.blur();
    }
  }

  return (
    <Button
      style={styles.buttonDesktop(serverResponse.leaflet.pages.length)}
      className="carousel-pagination-button"
      color="primary"
      active={false}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <input
          onFocus={(e) => {
            inputPage.current.classList.add("onFocus");
            e.target.placeholder = "";
          }}
          onBlur={(e) => (e.target.placeholder = carouselIndex.labelIndex)}
          id="input-page"
          ref={inputPage}
          type="text"
          onClick={(e) => e.target.select()}
          style={styles.inputDesktop}
          onKeyDown={(e) => change(e)}
          placeholder={carouselIndex.labelIndex}
        ></input>
        <span>/</span>
        <span style={{ marginLeft: 3 }}>
          {serverResponse.leaflet.pages.length}
        </span>
      </span>
    </Button>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps, { setIndexCarousel, isFromInput })(
  InputPage
);
