import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

import { connect } from "react-redux";

class NavList extends Component {
  state = {
    collapse: false,
    tooltip: false
  };

  toggle = (e) => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  componentDidMount = () => {
    // this is for opening collapse for active link
    if (this.props.list.child) {
      const finalList = this.props.list.child.filter(
        (a) => !a.hasOwnProperty("child")
      );
      const threeList = this.props.list.child.filter((a) =>
        a.hasOwnProperty("child")
      );
      const finalThreelevel = [];
      if (threeList.length) {
        threeList.forEach((element) => {
          element.child.forEach((ele) => {
            finalThreelevel.push(ele.routepath);
          });
        });
      }
      if (
        (finalList.length &&
          finalList
            .map((a) => a.routepath)
            .includes(this.props.location.pathname)) ||
        (finalThreelevel &&
          finalThreelevel.includes(this.props.location.pathname))
      ) {
        this.setState({
          collapse: true
        });
      }
    }
  };

  render() {
    let dynamicList;

    const { list, forceDrawerMini } = this.props;

    dynamicList = (
      <li
        className={this.props.mini ? "li_sidebar" : ""}
        id={list.name}
        style={{ position: "relative" }}
        onMouseEnter={() => {
          if (this.props.themeSetting === "on")
            this.setState({ tooltip: true });
        }}
        onMouseLeave={() => this.setState({ tooltip: false })}
      >
        <NavLink
          to={list.routepath}
          className="nav-link main-list"
          onClick={(e) => {
            forceDrawerMini();
            list.action(e);
          }}
        >
          <i className={list.iconClass} />
          <p
            className={this.props.mini ? "fade_p" : ""}
            style={{ opacity: this.props.mini ? null : "1" }}
          >
            {list.name}
          </p>
        </NavLink>
      </li>
    );

    return <Fragment>{dynamicList}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    themeSetting: state.themeSetting.sidebarMiniValue
  };
};

export default connect(mapStateToProps)(NavList);
