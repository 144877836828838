import { H_COMPONENTS } from "../../helper/constant";

export const styles = {
  prismaZoomContainer: {
    height: "100%",
  },
  pagerButtonsContainer: {
    margin: "0 0px",
  },
  pagerButtons: {
    borderRadius: "0",
  },
  pagerButton: {
    borderRadius: "0.25rem",
    margin: "0 10px",
  },
  pagersHeight: {
    height: "50px",
  },
  pagerSlider: {
    marginTop: "5px",
  },
  thumbsContainer: {
    position: "absolute",
    zIndex: "99999999999999",
    width: "200px",
    bottom: "30px",
    margin: "0 auto",
    right: "0",
    left: "0",
  },
  thumbsImg: {
    border: "solid 1px var(--secondary-color)",
  },
  pagerSliderStyle: {
    track: {
      backgroundColor: "rgb(221, 221, 221);",
      width: "100%",
    },
    active: {
      backgroundColor: "var(--primary-color)",
    },
    thumb: {
      backgroundColor: "var(--secondary-color)",
    },
  },
  adsenseContainer: {
    height: H_COMPONENTS.adsense,
  },
  videoTextAudio: { paddingLeft: "10px" },
  sliderStyle: {
    zIndex: "1",
    width: "100%",
    background: "var(--background-color)",
  },
};
