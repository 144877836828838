import { SET_COOKIE_INFO } from "../type";
import { serverResponse } from "../../util/fakeServer";

const initState = {
  analitics: serverResponse.config.ga_active,
  privacyPolicy: "",
  cookiePolicy: "",
  actualVersion: null,
  lastVersion: null,
  cookieAccept: null,
  cookieDecline: null,
  modifiedAt: null
};

export default function(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_COOKIE_INFO:
      return {
        ...state,
        privacyPolicy: payload.privacyPolicy,
        cookiePolicy: payload.cookiePolicy,
        cookieAccept: payload.cookieAccept,
        cookieDecline: payload.cookieDecline
      };

    default:
      return state;
  }
}
