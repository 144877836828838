import { SET_COOKIE_INFO } from "../type";
import { getCookieConsentValue } from "react-cookie-consent";

export const setValueCookie = () => async (dispatch) => {
  //QUI ANDRà INSERITA LA CHIAMATA API PER RECUPERARE LE INFORMAZIONI UTENTE

  const consent = getCookieConsentValue();
  let cookieAccept, cookieDecline;
  if (consent === undefined) {
    cookieAccept = null;
    cookieDecline = null;
  } else if (consent === "true") {
    cookieAccept = true;
    cookieDecline = false;
  } else {
    cookieAccept = false;
    cookieDecline = true;
  }

  dispatch({
    type: SET_COOKIE_INFO,
    payload: {
      privacyPolicy: "Privacy Policy",
      cookiePolicy: "CookiePolicy",
      cookieAccept,
      cookieDecline
    }
  });
};
