export const styles = {
    text: {
        display: "inline",
        width: "100%",
    },
    read_or_hide: {
        color: "var(--secondary-color)",
        fontWeight: "bold",
        cursor: "pointer",
    }
}