import React, { Component } from "react";

import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";

class SearchBar extends Component {
  state = {
    searchText: ""
  };

  handleClickSearch = () => {
    window.location = "/categories/" + this.state.searchText;
  };

  render() {
    let closeBtn = (
      <FontAwesomeIcon
        className="custom-popover-close-btn"
        icon={faTimes}
        onClick={this.props.toggle}
      />
    );

    return (
      <Popover
        className="header-popover-width custom-popover"
        isOpen={this.props.isOpen}
        target={this.props.target}
        placement={this.props.placement}
        toggle={this.props.toggle}
        trigger="block"
      >
        <PopoverHeader className="custom-popover-header text-center">
          Cerca
          {closeBtn}
        </PopoverHeader>
        <PopoverBody className="pa-0">
          <div>
            <InputGroup className="custom-search-bar-wrapper">
              <Input
                type="text"
                name="serach"
                id="search"
                placeholder={language[defaultLanguage].cerca_un_prodotto}
                defaultValue={this.state.searchText}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    this.handleClickSearch();
                  } else {
                    this.setState({ searchText: e.target.value });
                  }
                }}
              />
              <InputGroupAddon addonType="append">
                <span
                  className="input-group-text"
                  onClick={() => this.handleClickSearch()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </PopoverBody>
      </Popover>
    );
  }
}
export default SearchBar;
