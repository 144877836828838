import React from "react";
import { connect } from "react-redux";

const Overlay = (props) => {
  if (!props.floatingMenu) {
    return null;
  }
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
        zIndex: 9,
        position: "fixed",
        top: 0
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    floatingMenu: state.floatingMenu.isOpen
  };
};

export default connect(mapStateToProps, null)(Overlay);
