export const styles = {
    wrapper: {
        position: "relative",

    },
    varieties: {
        fontSize: "0.8rem",
        height: "20px",
        textAlign: "center",
        cursor: "pointer"
    },
    description: {
        fontSize: "0.6rem",
        textAlign: "left"
    },
    tooltip: (color) => {
        return {
            position: "absolute",
            backgroundColor: color,
            color: "white",
            borderRadius: "0.25rem",
            padding: ".2rem .75rem",
            zIndex: 1,
            fontSize: "0.7rem",
            cursor: "pointer"
        }
    }
}