import React, { Component } from "react";
// import Icon from '../../../assets/images/icons/play.svg';
import { Player } from "video-react";
import "../../../assets/css/video-react.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import HatChefIcon from '../../../assets/images/icons/hatchef.svg';
// import PlusIcon from '../../../assets/images/icons/plus.svg';
// import WorldIcon from '../../../assets/images/icons/world.svg';
// import InfoIcon from '../../../assets/images/icons/info.svg';
// import SpecsIcon from '../../../assets/images/icons/specs.svg';
// import PromoIcon from '../../../assets/images/icons/promo.svg';
// import { isMobile, isIOS } from 'react-device-detect';
import "../../../assets/css/custom.css";
import Facebook from "../../../assets/images/icons/facebook.svg";
import Whatsapp from "../../../assets/images/icons/whatsapp.svg";
import { serverResponse } from "../../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../../settings/gaConfig";
import { language } from "../../../languageProvider/language/language";
import { defaultLanguage } from "../../../helper/utility";

export default class ShowPriceMarker extends Component {
  // const { player } = this.player.getState();
  // console.log(player.currentTime);

  setModal = (swipe) => {
    //TODO
    //axios per l'incremento del counter di visualizzazioni della modal play
    /*
        if(this.state.modal === false){
            axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });
        }*/
    this.props.setSwipe(swipe);
    //this.props.leafletSwipe();
    // this.setState({ modal: !this.state.modal });
    // if (!this.state.modal) {
    //     this.setState({ show_price: false });
    // }
    // else {
    //     this.setState({ show_price: false });
    //     setTimeout(() => { this.setState({ show_price: true }) }, 15000);
    // }

    this.setState((state) => {
      if (!state.modal) {
        setTimeout(() => {
          this.setState({ show_price: true });
        }, 10000);
      }
      return { modal: !state.modal, show_price: false };
    });
  };

  shareOnFacebook = () => {
    let imageSrc =
      serverResponse.config.serverPath +
      this.props.info.images[this.props.info.images.length - 1].image_file;
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(imageSrc),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  shareOnWhatsapp = () => {
    let imageSrc =
      serverResponse.config.serverPath +
      this.props.info.images[this.props.info.images.length - 1].image_file;
    //window.location = "http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc);
    window.open(
      "http://api.whatsapp.com/send?text=" +
        serverResponse.config.shareProductSentence +
        "    " +
        encodeURIComponent(imageSrc),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  state = {
    modal: false,
    show_price: false
  };

  componentDidMount() {
    // subscribe state change
    // this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    // setTimeout(() => { this.setState({ show_price: true }) }, 15000);
  }

  componentWillUnmount() {
    // this.setState({ show_price: false });
  }

  handleStateChange(state, prevState) {
    // copy player state to this component's state
    this.setState({
      player: state,
      currentTime: state.currentTime
    });
  }

  render() {
    // const markers = this.props.info.markers.map((element, key) => {
    //     switch (element.type) {
    //         case "plus": {
    //             return <img key={key} src={PlusIcon} className="icon-marker" alt="Info" onClick={() => { this.props.navMarkers("plus"); this.setModal(false) }} />
    //         }
    //         case "info": {
    //             return <img key={key} src={InfoIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("info"); this.setModal(false) }} />
    //         }
    //         case "world": {
    //             return <img key={key} src={WorldIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("world"); this.setModal(false) }} />
    //         }
    //         case "hat-chef": {
    //             return <img key={key} src={HatChefIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("hatchef"); this.setModal(false) }} />
    //         }
    //         case "specs": {
    //             return <img key={key} src={SpecsIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("specs"); this.setModal(false) }} />
    //         }
    //         case "promo": {
    //             return <img key={key} src={PromoIcon} className="icon-marker-promo" alt="World" onClick={() => { this.props.navMarkers("promo"); this.setModal(false) }} />
    //         }
    //         case "play": {
    //             return null
    //         }
    //         default: return null
    //     }
    // });

    const customMarkerStyle = {
      background: "#fff",
      fontSize: "14px",
      fontWeight: "bold",
      padding: "4px",
      textShadow: "1px 1px 1px rgba(0, 0, 0, 0.004)",
      color: "#666",
      border: "1px solid #666",
      borderRadius: "6px"
    };

    return (
      <div
        className={"marker " + serverResponse.config.iconAnimation}
        type="plus"
      >
        {/* <img id={"play-" + this.props.info.item_id} src={Icon} className="icon-marker" alt="Play" onClick={() => this.setModal(false)} /> */}
        {this.props.showButton && (
          <Button
            style={{ marginBottom: "10px" }}
            color="primary"
            onClick={() => {
              this.setModal(false);
              if (serverResponse.config.ga_active) {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.open_product_show_price_video,
                  label: String(this.props.info.item_id)
                });
              }
            }}
          >
            {language[defaultLanguage].scopri_il_prezzo}
          </Button>
        )}
        {!this.props.showButton && (
          <span
            id={"showprice-" + this.props.info.item_id}
            style={customMarkerStyle}
            onClick={() => {
              this.setModal(false);
              if (serverResponse.config.ga_active) {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.open_product_show_price_video,
                  label: String(this.props.info.item_id)
                });
              }
            }}
          >
            {language[defaultLanguage].scopri}
          </span>
        )}

        <Modal
          isOpen={this.state.modal}
          toggle={() => this.setModal(true)}
          size="lg"
        >
          <ModalHeader toggle={() => this.setModal(true)}>Video</ModalHeader>
          <ModalBody>
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ /*display: "flex", */ maxHeight: "90px" }}>
                <div className="markers-container" style={{ width: "100%" }}>
                  {this.state.show_price && (
                    <div>
                      <Button
                        style={{ marginBottom: "10px" }}
                        color="primary"
                        onClick={() => {
                          this.props.navMarkers("plus");
                          this.setModal(false);
                          if (serverResponse.config.ga_active) {
                            ReactGA.event({
                              category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                              action: gaEventLabel.open_product_after_video,
                              label: String(this.props.info.item_id)
                            });
                          }
                        }}
                      >
                        {language[defaultLanguage].scopri}
                      </Button>
                    </div>
                  )}

                  {!this.state.show_price && (
                    <div>
                      {language[defaultLanguage].attendi_scopri_prezzo} "
                      {this.props.info.description}"
                    </div>
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Facebook}
                      className="share-icon"
                      style={{ marginLeft: "auto" }}
                      alt="facebook"
                      onClick={() => this.shareOnFacebook()}
                    />
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      onClick={() => this.shareOnWhatsapp()}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Player
              autoPlay={true}
              playsInline
              src={process.env.PUBLIC_URL + this.props.markerInfo.data}
              ref={(player) => {
                this.player = player;
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.setModal(true)}>
              {language[defaultLanguage].chiudi}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
