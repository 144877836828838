import React from "react"
import Components from './SearchCarouselItemIndex';
import { getCustomActions } from "../../helper/utility";

const Product = (props) => {
  const type = 'SearchCarouselItem' + getCustomActions().component;
  const ComponentToRender = Components[type];

  return < ComponentToRender element={props.element} />
}

export default Product