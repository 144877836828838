import React from "react";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../redux/carousel/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { styles } from "./carouselArrow.style";

const CarouselArrow = (props) => {
  const icon = props.direction === "left" ? faChevronLeft : faChevronRight;
  const iconFirstLast =
    props.direction === "left" ? faChevronLeft : faChevronRight;

  const isDouble = props.double !== undefined ? props.double : true;

  const carousel = props.carousel
    ? ""
    : "carousel-pagination-button btn btn-primary";
  const arrowColor =
    props.arrowColor !== undefined ? props.arrowColor : "white";

  const additionalIndex = props.direction === "left" ? -1 : +1;

  const colorDisabled = "#a2a4a6";

  return (
    <div style={styles.container}>
      {/* CONTAINER FIRST PAGE */}
      {props.direction === "left" && isDouble && (
        <div
          className="carousel-pagination-button btn btn-primary"
          style={Object.assign(
            {},
            styles.cursorStyle(props.carouselIndex.buttonCarouselDisabled),
            styles.borderLeft,
            {
              zIndex: "1",
              opacity: props.carouselIndex.buttonCarouselDisabled ? "0.7 " : "1"
            }
          )}
          onClick={() =>
            props.carouselIndex.buttonCarouselDisabled ||
            props.carouselIndex.carouselIndex === 0
              ? null
              : props.setIndexCarousel(0)
          }
        >
          <FontAwesomeIcon
            style={styles.cursorStyle(
              !props.carouselIndex.buttonCarouselDisabled
            )}
            color={
              props.carouselIndex.buttonCarouselDisabled
                ? colorDisabled
                : arrowColor
            }
            icon={iconFirstLast}
          />
          <FontAwesomeIcon
            style={styles.cursorStyle(
              !props.carouselIndex.buttonCarouselDisabled
            )}
            color={
              props.carouselIndex.buttonCarouselDisabled
                ? colorDisabled
                : arrowColor
            }
            icon={iconFirstLast}
          />
        </div>
      )}
      {/* SIMPLE ARROW */}
      <div
        className={carousel}
        style={Object.assign({}, styles.noBorderRadius, {
          zIndex: "1",
          opacity: props.carouselIndex.buttonCarouselDisabled ? "0.7 " : "1",
          fontSize: "40px"
        })}
        onClick={() =>
          props.carouselIndex.buttonCarouselDisabled ||
          (props.direction === "left" &&
            props.carouselIndex.carouselIndex === 0) ||
          (props.direction === "right" &&
            props.carouselIndex.carouselIndex === global.max - 1)
            ? null
            : props.setIndexCarousel(
                props.carouselIndex.carouselIndex + additionalIndex
              )
        }
      >
        <FontAwesomeIcon
          color={
            props.carouselIndex.buttonCarouselDisabled
              ? colorDisabled
              : arrowColor
          }
          style={styles.cursorStyle(props.carouselIndex.buttonCarouselDisabled)}
          icon={icon}
        />
      </div>

      {/* CONTAINER LAST PAGE */}
      {props.direction === "right" && isDouble && (
        <div
          className="carousel-pagination-button btn btn-primary"
          style={Object.assign(
            {},
            styles.cursorStyle(props.carouselIndex.buttonCarouselDisabled),
            styles.borderRight,
            {
              zIndex: "1",
              opacity: props.carouselIndex.buttonCarouselDisabled ? "0.7 " : "1"
            }
          )}
          onClick={() =>
            props.carouselIndex.buttonCarouselDisabled ||
            props.carouselIndex.carouselIndex === global.max - 1
              ? null
              : props.setIndexCarousel(global.max - 1)
          }
        >
          <FontAwesomeIcon
            color={
              props.carouselIndex.buttonCarouselDisabled
                ? colorDisabled
                : arrowColor
            }
            icon={iconFirstLast}
          />
          <FontAwesomeIcon
            color={
              props.carouselIndex.buttonCarouselDisabled
                ? colorDisabled
                : arrowColor
            }
            icon={iconFirstLast}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps, { setIndexCarousel })(CarouselArrow);
