import React, { useState } from "react";
// import "../App.css";
import { styles } from "./readMore.style";

const limit = 150;

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p style={styles.text}>
      {isReadMore && text.length > limit ? `${text.slice(0, limit)}...` : text}
      {text.length > limit && (
        <span onClick={toggleReadMore} style={styles.read_or_hide}>
          {isReadMore ? "▼ Leggi tutto" : "▲ Leggi meno"}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
