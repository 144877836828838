import React from "react";
import { serverResponse } from "../../util/fakeServer";
const Loader = ({
    width = "100vw",
    height = "100vh",
    background = serverResponse.config.background_color,
    single
}) => {
    const style = {
        background: "rgb(250, 250, 250)",
        width: "100vw",
        height: "100vh",
        zIndex: "10",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(!single && {
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
        })
    };
    return (
        <div style={style}>
            <div
                className="spinner-grow "
                style={{
                    width: "5rem",
                    height: "5rem",
                    color: serverResponse.config.secondary_color
                }}
                role="status"
            />
        </div>
    );
};
export default Loader;