import { serverResponse } from "../util/fakeServer";

export const AppName =
  serverResponse.config.type === "leaflet"
    ? "Volantino Interattivo"
    : serverResponse.config.type === "menu"
    ? "Menù Interattivo"
    : "Catalogo Interattivo";
export const drawerWidth = "260px";
export const miniDrawerWidth = "50px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";

export const leafLetIcon = require("assets/images/icons/icone/book-open-solid.svg");
export const columnIcon = require("assets/images/icons/icone/columns-solid.svg");
export const searchIcon = require("assets/images/icons/icone/magnifying-glass-solid.svg");
export const pdfIcon = require("assets/images/icons/icone/file-pdf-solid.svg");
export const shareIcon = require("assets/images/icons/icone/share-square-solid.svg");
export const indexIcon = require("assets/images/icons/icone/list-solid.svg");

export const iconDemo = serverResponse.config.hide_logo
  ? serverResponse.config.clientIcon
  : serverResponse.config.type === "leaflet"
  ? require("assets/images/logo_new.png")
  : serverResponse.config.type === "menu"
  ? require("assets/images/menu_logo.png")
  : require("assets/images/catalogo_logo.png");
export const iconAppName =
  serverResponse.config.type === "leaflet"
    ? require("assets/images/logo_interattivo.png")
    : serverResponse.config.type === "menu"
    ? require("assets/images/menu.png")
    : require("assets/images/catalogo.png");
export const iconAppNameNegative =
  serverResponse.config.type === "leaflet"
    ? require("assets/images/logo_interattivo_negative.png")
    : serverResponse.config.type === "menu"
    ? require("assets/images/menuNegative.png")
    : require("assets/images/catalogoNegative.png");

export const logoInterattivo = require("assets/images/Favicon.png");
export const logoInterattivoFree = require("assets/images/logo_interattivo_free.png");
export const logoVolantinoInterattivoFree = require("assets/images/logo_volantinointerattivo_free.svg");

//Custom color
export const primaryColor = "#e48a00";
export const secondaryColor = "#dc2311";
export const tertiaryColor = "#ffdd00";

//LocalStorage variables name
export const idCampaignName = "idcmpgn";
export const myGroceryListName = "mygrcrlst";

//ALTEZZE elementi fissi
export const H_COMPONENTS = {
  header: 48,
  adsense: 90,
  bottomBar: 42,
  pager: 50
};

export const BREAKPOINT = {
  xs: 576,
  md: 768,
  lg: 992,
  xl: 1200
};
