export const styles = {
  container: {
    display: "flex",
    alignItems: "center"
  },
  doubleArrowContainer: {
    display: "flex",
    alignItems: "center"
  },
  cursorStyle: (disabled) => {
    return {
      cursor: !disabled ? "pointer" : "inherit"
    };
  },
  noBorderRadius: {
    borderRadius: "0"
  },
  borderLeft: {
    borderRadius: "0",
    borderTopLeftRadius: "0.25rem",
    borderBottomLeftRadius: "0.25rem"
  },
  borderRight: {
    borderRadius: "0",
    borderTopRightRadius: "0.25rem",
    borderBottomRightRadius: "0.25rem"
  }
};
