const actions = {

    MENU_COLOR: 'MENU_COLOR',

    menuColor: (data) => {
        return {
            type: actions.MENU_COLOR,
            color: data
        }
    },

}

export default actions;