import React from "react";
import CookieConsent from "react-cookie-consent";
import { serverResponse } from "../../util/fakeServer";
import { getCustomActions } from "../../helper/utility";

const CookieConsentBanner = () => {
  const showCookieConsent = getCustomActions().cookieConsent;

  const isIframe = getCustomActions().isIframe();

  if (!showCookieConsent || isIframe) {
    return null;
  }

  return (
    <CookieConsent
      buttonText="Accetto"
      buttonStyle={{
        backgroundColor: serverResponse.config.secondary_color
      }}
    >
      Questo sito fa uso di cookie per migliorare l’esperienza di navigazione
      degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso.
      Utilizziamo sia cookie tecnici sia cookie di parti terze per inviare
      messaggi promozionali sulla base dei comportamenti degli utenti. Può
      conoscere i dettagli consultando la nostra
      <a
        href="https://www.giodicart.it/condizioni-di-vendita#privacy"
        rel="noopener noreferrer"
        target="_blank"
      >
        {" "}
        privacy policy qui
      </a>
      . Proseguendo nella navigazione si accetta l’uso dei cookie; in caso
      contrario è possibile abbandonare il sito.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
