import React, { Component } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
  Col,
  Tooltip
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faBars,
  faAngleLeft,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";

import AddProd from "../../assets/images/icons/white_add_prod.png";

import { NotificationManager } from "react-notifications";

import HatChefIcon from "../../assets/images/icons/hatchef.svg";
import SpecsIcon from "../../assets/images/icons/specs.svg";
// import PromoIcon from '../../assets/images/icons/promo.svg';
import InfoIcon from "../../assets/images/icons/info.svg";
import WorldIcon from "../../assets/images/icons/world.svg";
import PlayIcon from "../../assets/images/icons/play.svg";
import PlusIcon from "../../assets/images/icons/plus.svg";

import { Textfit } from "react-textfit";

import "../../assets/css/custom.css";
import { Player } from "video-react";
import "../../assets/css/video-react.css";
// import axios from 'axios';
import Facebook from "../../assets/images/icons/facebook.svg";
import Whatsapp from "../../assets/images/icons/whatsapp.svg";

import { serverResponse } from "../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../settings/gaConfig";

import { strings } from "../../util/data/strings";
// import RelatedProduct from '../../components/leaflet/RelatedProduct';
// import RelProductSearchCarousel from '../leaflet/RelProductSearchCarousel';

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { defaultLanguage, gAInitializer } from "../../helper/utility";
import { isIOS } from "react-device-detect";
import { language } from "../../languageProvider/language/language";

const productUrl = serverResponse.config.shareFlyerURL;

class SearchCarouselItemDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPlus: false,
      modalInfo: false,
      modalHatChef: false,
      modalPlay: false,
      quantity: this.props.element.quantity_step
        ? this.props.element.quantity_step
        : 1,
      varietySelected: this.props.element.varieties
        ? this.props.element.varieties[0]
        : "",
      markerType: "plus",
      saveTooltipOpen: false,
      products: []
    };
    this.setModalPlus = this.setModalPlus.bind(this);
  }

  // state = {
  //     modalPlus: false,
  //     modalInfo: false,
  //     modalHatChef: false,
  //     modalPlay: false,
  //     quantity: (this.props.element.quantity_step ? this.props.element.quantity_step : 1),
  //     varietySelected: (this.props.element.varieties ? this.props.element.varieties[0] : ""),
  //     markerType: "plus",
  //     saveTooltipOpen: false,
  //     products: []
  // }

  componentDidMount() {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
  }

  setModalPlus = (modalState) => {
    if (modalState) {
      //TODO
      //axios per l'incremento del counter di visualizzazioni della modal plus
      /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
    
                }).catch(error => {
                    //console.log(error);
                });*/
      this.setState({
        modalPlus: true,
        quantity: this.props.element.quantity_step
          ? this.props.element.quantity_step
          : 1,
        varietySelected: this.props.element.varieties
          ? this.props.element.varieties[0]
          : "",
        modalHatChef: false,
        modalPlay: false,
        modalInfo: false,
        markerType: "plus"
      });
    } else {
      this.setState({
        modalPlus: false,
        quantity: this.props.element.quantity_step
          ? this.props.element.quantity_step
          : 1,
        varietySelected: this.props.element.varieties
          ? this.props.element.varieties[0]
          : ""
      });
    }
  };

  setModalInfo = (modalState) => {
    if (modalState) {
      //TODO
      //axios per l'incremento del counter di visualizzazioni della modal info
      /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
    
                }).catch(error => {
                    //console.log(error);
                });*/
      this.setState({
        modalInfo: true,
        modalHatChef: false,
        modalPlay: false,
        modalPlus: false,
        markerType: "info"
      });
    } else {
      this.setState({
        modalInfo: false
      });
    }
  };
  setModalHatChef = (modalState) => {
    if (modalState) {
      //TODO
      //axios per l'incremento del counter di visualizzazioni della modal hat chef
      /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
    
                }).catch(error => {
                    //console.log(error);
                });*/
      this.setState({
        modalHatChef: true,
        modalPlay: false,
        modalPlus: false,
        modalInfo: false,
        markerType: "hatchef"
      });
    } else {
      this.setState({
        modalHatChef: false
      });
    }
  };
  setModalPlay = (modalState) => {
    if (modalState) {
      //TODO
      //axios per l'incremento del counter di visualizzazioni della modal play
      /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
    
                }).catch(error => {
                    //console.log(error);
                });*/
      this.setState({
        modalPlay: true,
        modalHatChef: false,
        modalPlus: false,
        modalInfo: false,
        markerType: "play"
      });
    } else {
      this.setState({
        modalPlay: false
      });
    }
  };

  incrementQty = () => {
    this.setState({
      quantity:
        this.state.quantity +
        (this.props.element.quantity_step
          ? this.props.element.quantity_step
          : 1)
    });
  };

  decrementQty = () => {
    if (this.props.element.quantity_step) {
      if (this.state.quantity > this.props.element.quantity_step) {
        this.setState({
          quantity: this.state.quantity - this.props.element.quantity_step
        });
      }
    } else if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  handleChangeVariety = (e) => {
    this.setState({
      varietySelected: e.target.value
    });
  };

  handleAddProduct = () => {
    let obj = Object.assign({}, this.props.element);
    obj.quantity = this.state.quantity;
    obj.done = false;
    obj.variety = this.state.varietySelected;
    this.props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );
  };

  shareOnFacebook = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_facebook,
        label: String(this.props.element.item_id)
      });
    }

    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.element.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  shareOnWhatsapp = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_whatsapp,
        label: String(this.props.element.item_id)
      });
    }

    let page_url =
      productUrl + "/share/product.php?c=" + this.props.element.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      console.log(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url)
      );
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  toggleSaveTooltip = () => {
    this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
  };

  handleRelatedClick = (item_id) => {
    this.setModalPlus(false);
    let marker = document.getElementById("plus-" + item_id);
    marker.click();
  };

  componentWillMount() {
    let tmpProducts = [];
    serverResponse.leaflet.pages.forEach((page) => {
      tmpProducts = tmpProducts.concat(page.products);
    });
    this.setState({
      products: tmpProducts
    });
  }

  render() {
    const markers = this.props.element.markers.map((element, key) => {
      switch (element.type) {
        case "plus": {
          if (this.state.markerType === "plus") {
            return null;
          } else {
            return (
              <img
                key={key}
                src={PlusIcon}
                className="icon-marker"
                alt="Plus"
                onClick={() => {
                  this.setModalPlus(true);
                }}
              />
            );
          }
        }
        case "info": {
          if (this.state.markerType === "info") {
            return null;
          } else {
            return (
              <img
                key={key}
                src={InfoIcon}
                className="icon-marker"
                alt="Info"
                onClick={() => {
                  this.setModalInfo(true);
                }}
              />
            );
          }
        }
        case "world": {
          return (
            <img
              key={key}
              src={WorldIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                window.open(element.data, "_blank");
              }}
            />
          );
        }
        case "hat-chef": {
          if (this.state.markerType === "hatchef") {
            return null;
          } else {
            return (
              <img
                key={key}
                src={HatChefIcon}
                className="icon-marker"
                alt="Hat-Chef"
                onClick={() => {
                  this.setModalHatChef(true);
                }}
              />
            );
          }
        }
        case "specs": {
          if (this.state.markerType === "specs") {
            return null;
          } else {
            return (
              <img
                key={key}
                src={SpecsIcon}
                className="icon-marker"
                alt="Specs"
                onClick={() => {
                  this.setModalHatChef(true);
                }}
              />
            );
          }
        }
        case "play": {
          if (this.state.markerType === "play") {
            return null;
          } else {
            return (
              <img
                key={key}
                src={PlayIcon}
                className="icon-marker"
                alt="Play"
                onClick={() => {
                  this.setModalPlay(true);
                }}
              />
            );
          }
        }
        case "promo": {
          if (this.state.markerType === "promo") {
            return null;
          } else {
            return (
              <img
                key={key}
                src={PlayIcon}
                className="icon-marker-pro"
                alt="Promo"
                onClick={() => {
                  this.setModalPlay(true);
                }}
              />
            );
          }
        }
        default:
          return null;
      }
    });

    const listImages = this.props.element.images.map((element, key) => (
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `${serverResponse.config.clientIcon}`;
        }}
        key={key}
        src={process.env.PUBLIC_URL + element.image_file}
        alt="img"
      />
    ));

    let varieties = "";
    if (this.props.element.varieties) {
      if (this.props.element.varieties.length > 0) {
        varieties = (
          <Row style={{ marginTop: "30px" }}>
            <Col md="12">
              <Label for="variety">Scegli:</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <span className="input-group-text icon-input-group">
                    <FontAwesomeIcon icon={faBars} />
                  </span>
                </InputGroupAddon>
                <Input
                  type="select"
                  name="select"
                  id="variety"
                  value={this.state.varietySelected}
                  onChange={this.handleChangeVariety}
                >
                  {this.props.element.varieties.map((element, key) => {
                    return (
                      <option key={key} value={element}>
                        {element}
                      </option>
                    );
                  })}
                </Input>
              </InputGroup>
            </Col>
          </Row>
        );
      }
    }

    let infoCarousel = undefined;
    // let hatchefCarousel = undefined;

    // let listHatchefIngredients = undefined;
    let listaSpecifiche = undefined;
    let specsImage = undefined;

    let markerInfo = undefined;
    // let markerPlay = undefined;
    let markerHatChef = undefined;

    let player = undefined;

    this.props.element.markers.forEach((element) => {
      if (element.type === "info") {
        if (element.data.img.length > 1) {
          infoCarousel = (
            <Carousel
              swipeable={true}
              emulateTouch={true}
              infiniteLoop={true}
              showStatus={false}
            >
              {element.data.img.map((element, key) => (
                <img
                  key={key}
                  src={
                    process.env.PUBLIC_URL + element ||
                    serverResponse.config.clientIcon
                  }
                  alt="img"
                />
              ))}
            </Carousel>
          );
        } else {
          infoCarousel = (
            <img
              style={{ width: "100%" }}
              src={
                process.env.PUBLIC_URL + element.data.img ||
                serverResponse.config.clientIcon
              }
              alt="img"
            />
          );
        }
        markerInfo = element;
      } else if (element.type === "hat-chef") {
        if (element.data.img.length > 1) {
          // let hatchefCarousel = <Carousel
          //     swipeable={true}
          //     emulateTouch={true}
          //     infiniteLoop={true}
          //     showStatus={false}>
          //     {element.data.img.map((element, key) =>
          //         <img key={key} src={process.env.PUBLIC_URL + element} alt="img" />
          //     )}
          // </Carousel>
        } else {
          // let hatchefCarousel = <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + element.data.img} alt="img" />
        }
        // let hatchefIngredients = element.data.ingredients.split(", ");
        // var listHatchefIngredients = hatchefIngredients.map((element, key) => {
        //     return <li key={key}>{element}</li>
        // }
        // );
        markerHatChef = element;
      } else if (element.type === "specs") {
        specsImage = (
          <img
            style={{ width: "100%" }}
            src={element.data.img || serverResponse.config.clientIcon}
            alt="img"
          />
        );
        let specifiche = element.data.specifiche.split(", ");
        listaSpecifiche = specifiche.map((element, key) => (
          <li key={key}>{element}</li>
        ));
        markerHatChef = element;
      } else if (element.type === "play") {
        if (
          !element.data.open_modal &&
          element.data.video_type === "video_file"
        ) {
          player = (
            <Player
              autoPlay={true}
              playsInline
              src={process.env.PUBLIC_URL + element.data.link}
            />
          );
        } else if (
          !element.data.open_modal &&
          element.data.video_type === "youtube"
        ) {
          player = (
            <div className="video-responsive" style={{ height: "50vh" }}>
              <iframe
                title={element.data.link}
                width="100%"
                height="100%"
                src={
                  "https://www.youtube.com/embed/" +
                  element.data.link +
                  "?autoplay=1&mute=1"
                }
              />
            </div>
          );
        } else if (
          element.data.open_modal === true &&
          element.data.video_type === "video_file"
        ) {
          player = (
            <Player
              autoPlay={true}
              playsInline
              src={process.env.PUBLIC_URL + element.data.link}
            />
          );
        } else if (
          element.data.open_modal === true &&
          element.data.video_type === "youtube"
        ) {
          player = (
            <div className="video-responsive" style={{ height: "50vh" }}>
              <iframe
                title={element.data.link}
                width="100%"
                height="100%"
                src={
                  "https://www.youtube.com/embed/" +
                  element.data.link +
                  "?autoplay=1&mute=1"
                }
              />
            </div>
          );
        }
      }
    });

    let brandImg = "";
    if (
      serverResponse.config.brandImage !== "" &&
      this.props.element.pam === true
    ) {
      brandImg = (
        <img
          style={{ width: "15%" }}
          src={process.env.PUBLIC_URL + serverResponse.config.brandImage}
          alt="img"
        />
      );
    }

    let cardImg = "";
    if (
      serverResponse.config.underPriceImage !== "" &&
      this.props.element.underpriced_product === true
    ) {
      cardImg = (
        <img
          style={{ width: "50%", marginTop: "10px" }}
          src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage}
          alt="img"
        />
      );
    }
    let related = [];
    // if (this.props.element.related) {
    //     this.props.element.related.map(element => {
    //         this.state.products.map((prod, key) => {
    //             if (prod !== undefined && prod.item_id === element) {
    //                 related.push(
    //                     <RelatedProduct key={key} prod={prod} handleClick={this.handleRelatedClick} />
    //                 );
    //             }
    //             return 0;
    //         })
    //         return 0;
    //     })
    //     return 0;
    // }

    let responsive = {
      0: { items: 5 },
      1024: { items: 5 }
    };

    var closeBtnPlus = (
      <Button color="secondary" onClick={() => this.setModalPlus(false)}>
        <i className="fas fa-arrow-left"></i>
        <strong> INDIETRO</strong>
      </Button>
    );
    var closeBtnPlay = (
      <Button color="secondary" onClick={() => this.setModalPlay(false)}>
        <i className="fas fa-arrow-left"></i>
        <strong> INDIETRO</strong>
      </Button>
    );
    var closeBtnInfo = (
      <Button color="secondary" onClick={() => this.setModalInfo(false)}>
        <i className="fas fa-arrow-left"></i>
        <strong> INDIETRO</strong>
      </Button>
    );
    // var closeBtnSpecs = <Button color="secondary" onClick={() => this.setModalSpecs(false)}><i className="fas fa-arrow-left"></i><strong> INDIETRO</strong></Button>
    var closeBtnHatChef = (
      <Button color="secondary" onClick={() => this.setModalHatChef(false)}>
        <i className="fas fa-arrow-left"></i>
        <strong> INDIETRO</strong>
      </Button>
    );

    return (
      <div>
        <div
          style={{ padding: "5%", cursor: "pointer" }}
          onClick={() => {
            this.setModalPlus(true);
            if (serverResponse.config.ga_active) {
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.searched_product,
                label: String(this.props.element.item_id)
              });
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.open_product,
                label: String(this.props.element.item_id)
              });
            }
          }}
        >
          <Row style={{ padding: "5%", width: "100%" }}>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${serverResponse.config.clientIcon}`;
              }}
              style={{ width: "100%", height: "100%" }}
              src={
                this.props.element.images.length > 0 &&
                this.props.element.images &&
                this.props.element.images !== null &&
                this.props.element.images !== undefined &&
                this.props.element.images[0].image_file !== undefined &&
                this.props.element.images[0].hasOwnProperty("image_file")
                  ? process.env.PUBLIC_URL +
                    this.props.element.images[0].image_file
                  : serverResponse.config.clientIcon
              }
              alt="img"
            />
          </Row>
          <Row
            style={{
              padding: "5%",
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
              {this.props.element.field1}{" "}
              {this.props.element.varieties.length > 0
                ? `- ${this.props.element.varieties[0]}`
                : null}
            </span>
          </Row>
        </div>
        {/** MODAL PLUS */}
        <Modal
          isOpen={this.state.modalPlus}
          toggle={() => this.setModalPlus(false)}
          size="xl"
        >
          <ModalHeader
            toggle={() => this.setModalPlus(false)}
            close={closeBtnPlus}
          >
            Info
          </ModalHeader>

          <ModalBody className="modal-plus-desktop">
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ display: "flex", height: "30px" }}>
                {markers}
                <img
                  src={Facebook}
                  className="share-icon"
                  style={{ marginLeft: "auto" }}
                  alt="facebook"
                  onClick={() => this.shareOnFacebook()}
                />
                <img
                  src={Whatsapp}
                  className="share-icon"
                  alt="whatsapp"
                  onClick={() => this.shareOnWhatsapp()}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Carousel
                  swipeable={true}
                  emulateTouch={true}
                  infiniteLoop={true}
                  showStatus={false}
                >
                  {listImages}
                </Carousel>
                {(listImages === undefined ||
                  listImages === null ||
                  listImages.length === 0) && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <img
                      alt="clientIcon"
                      src={serverResponse.config.clientIcon}
                    />
                  </div>
                )}
              </Col>
              <Col md="6">
                <Textfit mode="multi" min={30} max={30}>
                  <span
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    {this.props.element.field1}
                  </span>
                </Textfit>
                <Textfit mode="multi" min={15} max={15}>
                  <span style={{ textTransform: "uppercase" }}>
                    {this.props.element.field2}
                  </span>
                </Textfit>
                <h6 style={{ textTransform: "lowercase" }}>
                  {this.props.element.field3}
                </h6>
                <h6 style={{ textTransform: "lowercase" }}>
                  {this.props.element.field4}
                </h6>
                {brandImg}
                {cardImg}

                <Row style={{ marginTop: "30px" }}>
                  <Col md="12" className="product-price">
                    <h3 style={{ fontWeight: "bold" }}>
                      <span style={{ fontSize: "3rem" }}>
                        {this.props.element.price_label}
                      </span>
                    </h3>
                  </Col>
                </Row>
                {varieties}

                <Row style={{ marginTop: "30px" }}>
                  <Col md="12">
                    {!this.props.element.one_and_one_gratis && (
                      <div
                        className="container-add-to-list"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="container-add-to-list">
                          <FontAwesomeIcon
                            className="change-product-quantity-icon"
                            icon={faMinusCircle}
                            onClick={() => this.decrementQty()}
                          />
                          <h6>{this.state.quantity}</h6>
                          <h6 style={{ fontWeight: "bold" }}>
                            {this.props.element.grocery_label}
                          </h6>
                          <FontAwesomeIcon
                            className="change-product-quantity-icon"
                            icon={faPlusCircle}
                            onClick={() => this.incrementQty()}
                          />
                        </div>
                        <div className="container-add-to-list">
                          <Button
                            id={"btnSave_" + this.props.element.item_id}
                            color="primary"
                            onClick={this.handleAddProduct}
                          >
                            <img
                              src={AddProd}
                              style={{
                                color: "#00ff00",
                                width: "30px",
                                cursor: "pointer"
                              }}
                              alt="img"
                            />
                          </Button>
                          <Tooltip
                            placement="top"
                            isOpen={this.state.saveTooltipOpen}
                            target={"btnSave_" + this.props.element.item_id}
                            toggle={this.toggleSaveTooltip}
                          >
                            {strings.saveBtnTooltip}
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {/* {this.props.element.related.length > 0 &&  this.props.element.related.map((rel,index) => {
                                   return <RelProductSearchCarousel handleClick={this.handleRelatedClick} key={index} id={rel} />
                               })} */}
              <span>
                {related.length > 0 ? strings.relatedProductsTitle : ""}
              </span>
            </Row>
            <Row>
              <Col>
                <div
                  className="related_products_container"
                  style={{
                    width: "100%",
                    height: "200px",
                    paddingLeft: "3%",
                    paddingRight: "3%",
                    display: related.length > 0 ? "flex" : "none",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white"
                  }}
                >
                  <div className="">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      size="2x"
                      onClick={() => this.Carousel.slidePrev()}
                      className="search-carousel-icon"
                    />
                  </div>

                  <AliceCarousel
                    items={related}
                    responsive={responsive}
                    autoPlay={false}
                    fadeOutAnimation={true}
                    playButtonEnabled={false}
                    disableAutoPlayOnAction={true}
                    dotsDisabled={true}
                    buttonsDisabled={true}
                    ref={(el) => (this.Carousel = el)}
                    infinite={false}
                    mouseDragEnabled={false}
                  />
                  <div className="">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="2x"
                      onClick={() => this.Carousel.slideNext()}
                      className="search-carousel-icon"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={() => this.setModalPlus(false)}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
        {/** MODAL PLUS */}

        {/** MODAL INFO */}
        <Modal
          isOpen={this.state.modalInfo}
          toggle={() => this.setModalInfo(false)}
          size="xl"
        >
          <ModalHeader
            toggle={() => this.setModalInfo(false)}
            close={closeBtnInfo}
          >
            Curiosità
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ display: "flex", height: "30px" }}>
                {markers}
                <img
                  src={Facebook}
                  className="share-icon"
                  style={{ marginLeft: "auto" }}
                  alt="facebook"
                  onClick={() => this.shareOnFacebook()}
                />
                <img
                  src={Whatsapp}
                  className="share-icon"
                  alt="whatsapp"
                  onClick={() => this.shareOnWhatsapp()}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col className="col-4">{infoCarousel}</Col>
              <Col className="col-8">
                <h4 style={{ textTransform: "uppercase" }}>
                  {markerInfo ? markerInfo.data.titolo : ""}
                </h4>
                <p>{markerInfo ? markerInfo.data.testo : ""}</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.setModalInfo(false)}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
        {/** MODAL INFO */}

        {/** MODAL HATCHEF */}
        <Modal
          isOpen={this.state.modalHatChef}
          toggle={() => this.setModalHatChef(false)}
          size="xl"
        >
          <ModalHeader
            toggle={() => this.setModalHatChef(false)}
            close={closeBtnHatChef}
          >
            Scheda Tecnica
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ display: "flex", height: "30px" }}>
                {markers}
                <img
                  src={Facebook}
                  className="share-icon"
                  style={{ marginLeft: "auto" }}
                  alt="facebook"
                  onClick={() => this.shareOnFacebook()}
                />
                <img
                  src={Whatsapp}
                  className="share-icon"
                  alt="whatsapp"
                  onClick={() => this.shareOnWhatsapp()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h4
                  style={{ textTransform: "uppercase", marginBottom: "15px" }}
                >
                  {markerHatChef ? markerHatChef.title : ""}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col
                xs="12"
                sm="6"
                md="6"
                xl="6"
                style={{ marginBottom: "15px" }}
              >
                {specsImage}
              </Col>
              <Col xs="12" sm="6" md="6" xl="6">
                <h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
                  SPECIFICHE
                </h6>
                <ul>{listaSpecifiche}</ul>
              </Col>
            </Row>

            <Row>
              <Col>
                {/* <h6 style={{ marginTop: "10px", fontWeight: "bold" }}></h6> */}
                <p>{markerHatChef ? markerHatChef.data.recipe : ""}</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.setModalHatChef(false)}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
        {/** MODAL HATCHEF */}

        {/** MODAL PLAY */}

        <Modal
          isOpen={this.state.modalPlay}
          toggle={() => this.setModalPlay(false)}
          size="xl"
        >
          <ModalHeader
            toggle={() => this.setModalPlay(false)}
            close={closeBtnPlay}
          >
            Video
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ display: "flex", height: "30px" }}>
                {markers}
                <img
                  src={Facebook}
                  className="share-icon"
                  style={{ marginLeft: "auto" }}
                  alt="facebook"
                  onClick={() => this.shareOnFacebook()}
                />
                <img
                  src={Whatsapp}
                  className="share-icon"
                  alt="whatsapp"
                  onClick={() => this.shareOnWhatsapp()}
                />
              </Col>
            </Row>

            {player}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.setModalPlay(false)}>
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
        {/** MODAL PLAY */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    }
  };
}

export default connect(null, mapDispatchToProps)(SearchCarouselItemDefault);
