import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
  Button
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { serverResponse } from "../util/fakeServer";
import { defaultLanguage, getCustomActions } from "../helper/utility";
import { withRouter } from "react-router-dom";
import { styles } from "./retrieveCart.styles";
import { connect } from "react-redux";
import actions from "../redux/groceryList/action";
import { idCampaignName, myGroceryListName } from "../helper/constant";
import { language } from "../languageProvider/language/language";
const RetrieveCart = (props) => {
  const [id, setId] = useState("");
  const [_, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [items, setItems] = useState([]);
  const [result, setResult] = useState(false);

  const reset = () => {
    setResult([]);
    setLoading(false);
    setNotFound(false);
    setId("");
    setItems([]);
  };

  const setList = () => {
    items.forEach((element) => {
      props.addProduct(element);
    });
    localStorage.setItem(
      myGroceryListName + serverResponse.leaflet.code,
      JSON.stringify(items)
    );
    localStorage.setItem(idCampaignName, items ? items[0].id_campagna : "");

    props.setOpenModal(false);
  };

  const handleOnPress = async () => {
    setLoading(true);
    const link = getCustomActions().get_read_api_url(
      id,
      serverResponse.leaflet.id_campaign
    );
    try {
      const data = await axios.get(link);
      const temp_data = getCustomActions().listData(data);

      if (
        temp_data.length > 0 &&
        serverResponse.leaflet.id_campaign === temp_data[0].id_campagna
      ) {
        setResult(true);

        const temp_items = getCustomActions().get_product_api_url(data);

        setItems(temp_items);

        return;
      } else {
        setResult(false);
        setItems([]);
      }
      setNotFound(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>Ricerca carrello</ModalHeader>
      <ModalBody>
        Inserisci il codice lista per recuperare il tuo carrello
        <InputGroup>
          <Input
            type="text"
            name="serach"
            id="search"
            placeholder={language[defaultLanguage].cerca_un_prodotto}
            value={id}
            onChange={(e) => {
              if (notFound) {
                setNotFound(false);
              }

              setId(e.target.value);
            }}
          />
          <InputGroupAddon onClick={() => handleOnPress()} addonType="append">
            <button className="input-group-text" style={{ height: "38px" }}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </InputGroupAddon>
        </InputGroup>
        {result && items.length > 0 && (
          <>
            <h6 style={{ color: "green" }}>
              Abbiamo trovato trovato il tuo carrello ! Clicca in basso per
              recuperarlo :)
            </h6>

            <Table>
              <thead>
                <tr>
                  <th>N°</th>
                  <th></th>
                  <th>Prodotto</th>
                  <th>Quantità</th>
                  <th>Prezzo</th>
                </tr>
              </thead>
              <tbody>
                {items.map((el, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <img
                          style={styles.img}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${serverResponse.config.clientIcon}`;
                          }}
                          src={el.image_file}
                          alt={el.field1}
                        />
                      </td>
                      <td>
                        {" "}
                        <p style={styles.field}>{el.field1}</p>
                      </td>
                      <td>
                        <p>{el.quantity}</p>
                      </td>
                      <td>
                        <p>€{el.currentPrice}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div style={styles.addCartContainer}>
              <Button onClick={setList}>Aggiungi</Button>
            </div>
          </>
        )}
        {notFound && (
          <h6 style={{ color: "red" }}>
            Siamo spiacenti , non siamo riusciti a trovare questa lista !
          </h6>
        )}
      </ModalBody>
      <ModalFooter>
        <p
          onClick={() => {
            props.setOpenModal(false);
            reset();
          }}
        >
          Chiudi
        </p>
      </ModalFooter>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    }
  };
}

export default withRouter(connect(null, mapDispatchToProps)(RetrieveCart));
