import React, { useEffect } from "react";
import EmailIcon from "react-share/lib/EmailIcon";
import EmailShareButton from "react-share/lib/EmailShareButton";
import FacebookShareButton from "react-share/lib/FacebookShareButton";
import TelegramIcon from "react-share/lib/TelegramIcon";
import TelegramShareButton from "react-share/lib/TelegramShareButton";
import TwitterIcon from "react-share/lib/TwitterIcon";
import TwitterShareButton from "react-share/lib/TwitterShareButton";
import WhatsappIcon from "react-share/lib/WhatsappIcon";
import WhatsappShareButton from "react-share/lib/WhatsappShareButton";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Row from "reactstrap/lib/Row";
import AdComponent from "../leaflet/Adsense";
import ReactGA from "react-ga";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import FacebookIcon from "react-share/lib/FacebookIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { styles } from "./shareModal.style";
import { defaultLanguage, gAInitializer } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";

const link = serverResponse.config.shareFlyerURL;
const title = serverResponse.config.shareFlyerTitle;

const ShareModal = ({ open, setOpen }) => {
  const toggle = () => {
    setOpen(false);
  };

  const copyToClip = () => {
    navigator.clipboard
      .writeText(serverResponse.config.shareFlyerURL)
      .then(() =>
        NotificationManager.success(
          "Elemento Copiato",
          "Link Copiato con successo ",
          1000
        )
      )
      .catch((err) => {
        NotificationManager.error(
          "Errore nella copia",
          "Link Non Copiato",
          1000
        );
      });
  };

  const openReactNativeShare = () => {
    const str = title + "    " + link;
    const obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  useEffect(() => {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
  }, []);

  return (
    <div>
      <Modal centered isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {language[defaultLanguage].condividi_con}
        </ModalHeader>
        <ModalBody>
          <Row className="custom-share-container">
            <FacebookShareButton
              className="facebookShareButton shareIcon"
              url={link}
              beforeOnClick={() => {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.share_leaflet_facebook
                });
              }}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
              className="twitterShareIcon shareIcon"
              title={title}
              url={link}
              beforeOnClick={() => {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.share_leaflet_twitter
                });
              }}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <TelegramShareButton
              className="telegramShareIcon shareIcon"
              title={title}
              url={link}
              beforeOnClick={() => {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.share_leaflet_telegram
                });
              }}
            >
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>

            <WhatsappShareButton
              className="whatsappShareIcon shareIcon"
              style={styles.wa1}
              title={title}
              url={link}
              beforeOnClick={() => {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.share_leaflet_whatsapp
                });
              }}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <span style={styles.wa2} onClick={() => openReactNativeShare()}>
              <WhatsappIcon size={32} round={true} />
            </span>

            <EmailShareButton
              className="emailShareIcon shareIcon"
              url={link}
              subject={title}
              openWindow={false}
              beforeOnClick={() => {
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.share_leaflet_mail
                });
              }}
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>

            <div
              className="copyButtonVolantino shareIcon"
              onClick={copyToClip}
              style={styles.copyButton}
            >
              <FontAwesomeIcon
                style={{ margin: 0 }}
                color="white"
                size="sm"
                icon={faCopy}
              />
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          {serverResponse.config.adsense && <AdComponent />}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ShareModal;
