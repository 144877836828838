import React, { useState, useEffect } from "react";
import HeaderWrapper from "./header.style";
import { iconAppNameNegative } from "helper/constant";
import InputPage from "../common/InputPage";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import GroceryList from "../floatingGroceryList/GroceryList";
import SearchBar from "../searchBar/SearchBar";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { serverResponse } from "../../util/fakeServer";
import ReactGA from "react-ga";
import { setIndexCarousel, isFromInput } from "../../redux/carousel/action";
import ZoomButton from "../leaflet/ZoomButton";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import {
  BREAKPOINT,
  H_COMPONENTS,
  logoInterattivo,
  logoInterattivoFree
} from "../../helper/constant";
import {
  checkMobileOs,
  defaultLanguage,
  gAInitializer,
  getPager
} from "../../helper/utility";
import { language } from "../../languageProvider/language/language";

const { logout } = AuthActions;

const Header = (props) => {
  useEffect(() => {
    gAInitializer();
  });

  const { drawerMiniMethod, mini } = props;

  let [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
    if (popoverOpen) {
      ReactGA.pageview(
        `/${serverResponse.config.release_id}-${serverResponse.leaflet.id}/lista-spesa`
      );
    }
  };

  let [searchBarOpen, setSearchBarOpen] = useState(false);

  const searchBarToggle = () => {
    setSearchBarOpen(!searchBarOpen);
  };

  return (
    <HeaderWrapper {...props}>
      <div className="container-fluid" style={{ height: H_COMPONENTS.header }}>
        <div className="row h-100">
          <div className="col-3 p-md-1 p-2 d-lg-none h-100">
            {!serverResponse.config.no_link_to_site && (
              <a
                href="https://www.volantinointerattivo.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  id="logo_header"
                  src={logoInterattivoFree}
                  alt="react-logo"
                  style={{ height: "100%" }}
                />
              </a>
            )}
            {serverResponse.config.no_link_to_site && (
              <img src={iconAppNameNegative} alt="react-logo" />
            )}
          </div>
          <div className="col-6 d-lg-none d-flex justify-content-center align-items-center">
            {getPager() === "slider" &&
              props.location.pathname !== "/categories" &&
              props.location.pathname !== "/pages" && (
                <li className="list-inline-item d-flex align-items-center">
                  <InputPage />
                </li>
              )}
          </div>
          <div className="col-3 d-flex align-items-center justify-content-end d-lg-none h-100">
            {/* {serverResponse.leaflet.index !== null && (
              <button
                className="top-header-icon btn"
                id="mobile-grocery"
                onClick={() => props.setIndexCarousel(0)}
              >
                <i
                  id="mobile_index_icon"
                  style={{
                    color: "var(--primary-color)",

                    fontSize: "25px"
                  }}
                  className="fas fa-list-ul"
                ></i>
              </button>
            )} */}
            <button
              style={{
                position: "relative"
              }}
              className="top-header-icon btn"
              id="mobile-grocery"
              onClick={toggle}
            >
              {serverResponse.config.send_grocery_list_to_market &&
                !serverResponse.config.hide_grocery_list && (
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    style={{
                      fontSize: "25px",
                      color: "var(--primary-color)"
                    }}
                  />
                )}
              {!serverResponse.config.send_grocery_list_to_market &&
                !serverResponse.config.hide_grocery_list && (
                  <div id="custom_grocery_button" className="mr-3 btn">
                    <i
                      className="fas fa-shopping-basket"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "25px "
                      }}
                    ></i>
                  </div>
                )}
              {props.groceryList.length > 0 && (
                <span
                  style={{ position: "absolute" }}
                  className="custom-mobile-grocery-notification-badge"
                >
                  {props.groceryList.length}
                </span>
              )}
            </button>
          </div>
          <div className="col-12 d-none d-lg-flex justify-content-center">
            <div className="container-fluid p-0 custom_header_container_fluid">
              <div className="row h-100">
                <div className="col-lg-1 p-0 d-flex align-items-center">
                  <div className="headerBack mb-0" style={{ display: "flex" }}>
                    <div className="drawer-handle-arrow d-none d-lg-block">
                      {mini ? (
                        <button
                          className="top-header-icon c-btn"
                          onClick={drawerMiniMethod()}
                        >
                          <i className="fas fa-arrow-right" />
                        </button>
                      ) : (
                        <button
                          className="top-header-icon c-btn"
                          onClick={drawerMiniMethod()}
                        >
                          <i className="fas fa-arrow-left" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 d-flex align-items-center justify-content-center">
                  {!serverResponse.config.hide_searchbar &&
                    props.location.pathname !== "/categories" && (
                      <div className="list-inline-item d-none d-lg-flex align-items-center w-25 custom-search-li">
                        <InputGroup>
                          <Input
                            type="text"
                            name="serach"
                            id="search"
                            placeholder={
                              language[defaultLanguage].cerca_un_prodotto
                            }
                            value={props.searchText}
                            onChange={props.handleSearchTextChange}
                            onKeyUp={(e) => {
                              props.handleSearch2(props.searchText);
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <span
                              className="input-group-text"
                              style={{ height: "38px" }}
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </span>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                    )}
                  {getPager() === "slider" &&
                    props.location.pathname !== "/categories" &&
                    props.location.pathname !== "/pages" && (
                      <li className="list-inline-item d-flex align-items-center">
                        <InputPage />
                      </li>
                    )}
                  <ZoomButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!serverResponse.config.hide_grocery_list && (
        <GroceryList
          isOpen={popoverOpen}
          toggle={toggle}
          placement={"bottom"}
          target="mobile-grocery"
          isMobile={true}
        />
      )}
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList.list,
    carouselIndex: state.carousel
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout, setIndexCarousel, isFromInput })
)(Header);
