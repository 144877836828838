import React, { Component, Fragment } from "react";
import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon
} from "react-share";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../settings/gaConfig";
import { isIOS } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import AdComponent from "../leaflet/Adsense";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";

class ShareFlyer extends Component {
  state = {
    modal: false,
    idParam: 0,
    copyAlert: false,
    indexTooltip: false
  };

  openModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  openReactNativeShare = () => {
    let link = serverResponse.config.shareFlyerURL;
    let title = serverResponse.config.shareFlyerTitle;
    var str = title + "    " + link;
    var obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  componentDidMount() {
    var idParam = Math.random()
      .toString(36)
      .substr(2, 16);

    this.setState({
      idParam: idParam
    });

    if (serverResponse.config.ga_active) {
      ReactGA.initialize(serverResponse.config.ga_tracking_id, {
        gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" }
      });
    }
  }

  pause = (timeoutMsec) =>
    new Promise((resolve) => setTimeout(resolve, timeoutMsec));

  copyToClip = () => {
    navigator.clipboard
      .writeText(serverResponse.config.shareFlyerURL)
      .then(() =>
        NotificationManager.success(
          "Elemento Copiato",
          "Link Copiato con successo ",
          1000
        )
      )
      .catch((err) => {
        NotificationManager.error(
          "Errore nella copia",
          "Link Non Copiato",
          1000
        );
      });
  };

  render() {
    let link = serverResponse.config.shareFlyerURL;
    let title = serverResponse.config.shareFlyerTitle;

    let wa1 = "inline";
    let wa2 = "none";

    if (window.ReactNativeWebView !== undefined && isIOS) {
      wa1 = "none";
      wa2 = "inline";
    }
    const titleHover =
      serverResponse.config.type === "leaflet"
        ? "Volantino"
        : serverResponse.config.type === "menu"
        ? "Menù"
        : "Catalogo";

    return (
      <Fragment>
        <li
          className={`${this.props.themeSetting === "on" ? "li_sidebar" : ""}`}
          id="shareFlyerIconSidebar"
          style={{ position: "relative" }}
          onMouseEnter={() => {
            if (this.props.themeSetting === "on") {
              this.setState({ indexTooltip: true });
            }
          }}
          onMouseLeave={() => this.setState({ indexTooltip: false })}
        >
          <Link
            to="/"
            className="nav-link main-list"
            onClick={(e) => {
              e.preventDefault();
              this.props.forceDrawerMini();
              this.openModal();
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-share-square" title={titleHover} />
            <p
              style={{
                opacity: this.props.themeSetting === "on" ? null : "1"
              }}
              // title={`Condividi ${titleHover}`}
              className={this.props.themeSetting === "on" ? "fade_p" : ""}
            >
              {language[defaultLanguage].condividi}{" "}
              {serverResponse.config.type === "leaflet"
                ? language[defaultLanguage].volantino
                : serverResponse.config.type === "menu"
                ? language[defaultLanguage].menu
                : language[defaultLanguage].catalogo}
            </p>
          </Link>
        </li>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.closeModal()}
          centered={true}
        >
          <ModalHeader toggle={() => this.closeModal()}>
            {language[defaultLanguage].condividi_con}...
          </ModalHeader>
          <ModalBody>
            <Row className="custom-share-container">
              <FacebookShareButton
                className="facebookShareButton shareIcon"
                url={link}
                beforeOnClick={() => {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.share_leaflet_facebook
                  });
                }}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>

              <TwitterShareButton
                className="twitterShareIcon shareIcon"
                title={title}
                url={link}
                beforeOnClick={() => {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.share_leaflet_twitter
                  });
                }}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>

              <TelegramShareButton
                className="telegramShareIcon shareIcon"
                title={title}
                url={link}
                beforeOnClick={() => {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.share_leaflet_telegram
                  });
                }}
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>

              <WhatsappShareButton
                className="whatsappShareIcon shareIcon"
                style={{ display: wa1 }}
                title={title}
                url={link}
                beforeOnClick={() => {
                  this.openReactNativeShare()
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.share_leaflet_whatsapp
                  });
                }}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>

              <span
                style={{ display: wa2 }}
                onClick={() => this.openReactNativeShare()}
              >
                <WhatsappIcon size={32} round={true} />
              </span>
              <EmailShareButton
                className="emailShareIcon shareIcon"
                url={link}
                subject={title}
                openWindow={false}
                beforeOnClick={() => {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.share_leaflet_mail
                  });
                }}
              >
                <EmailIcon size={32} round={true} />
              </EmailShareButton>

              <div
                className="copyButtonVolantino shareIcon"
                onClick={() => {
                  this.copyToClip();
                }}
                style={{
                  background: "#d94a4a",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "360px",
                  width: "32px",
                  height: "32px"
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: 0 }}
                  color="white"
                  size="sm"
                  icon={faCopy}
                />
              </div>
              {this.state.copyAlert && <sup>Copy!</sup>}
            </Row>
          </ModalBody>
          <ModalFooter>
            {serverResponse.config.adsense && <AdComponent />}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList,
    themeSetting: state.themeSetting.sidebarMiniValue
  };
};

export default connect(mapStateToProps, null)(ShareFlyer);
