import React, { useRef, useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { FloatingButton, Item } from "react-floating-button";
import {
  leafLetIcon,
  columnIcon,
  searchIcon,
  pdfIcon,
  shareIcon,
  indexIcon
} from "../../helper/constant";
import { setStatusOfMenu } from "../../redux/floatingMenu/action";
import { serverResponse } from "../../util/fakeServer";
import { useLocation, useHistory } from "react-router-dom";
import { setIndexCarousel } from "../../redux/carousel/action";
import ShareModal from "../common/ShareModal";

const WAIT_BEFORE_SHOW = 500;

const FloatingMenu = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const [isShown, setIsShown] = useState(false);

  const arrayToFilter = serverResponse.config.sidebar;

  const [openShareModal, setOpenShareModal] = useState(false);

  const { pathname } = useLocation();

  const {
    config: { primary_color, secondary_color }
  } = serverResponse;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, WAIT_BEFORE_SHOW);
    return () => {
      clearTimeout(timer);
      setIsShown(false);
    };
  }, [WAIT_BEFORE_SHOW, pathname]);

  const handleOnClick = useCallback((value) => history.push(value), [history]);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.setStatusOfMenu(false);
    }
  };

  const arrayEl = [
    {
      id: "1",
      key: "leaflet",
      backgroundColor: pathname.includes("leaflet")
        ? secondary_color
        : primary_color,
      imgSrc: leafLetIcon,
      onClick: () => handleOnClick("/leaflet")
    },
    {
      id: "2",
      key: "products",
      backgroundColor: pathname.includes("categories")
        ? secondary_color
        : primary_color,
      imgSrc: searchIcon,
      onClick: () => handleOnClick("/categories")
    },
    {
      id: "3",
      key: "pages",
      backgroundColor: pathname.includes("pages")
        ? secondary_color
        : primary_color,
      imgSrc: columnIcon,
      onClick: () => handleOnClick("/pages")
    },
    {
      id: "4",
      key: "pdf",
      backgroundColor: primary_color,
      imgSrc: pdfIcon,
      onClick: () => window.open(serverResponse.config.pdfPath)
    },
    {
      id: "5",
      key: "share",
      backgroundColor: primary_color,
      imgSrc: shareIcon,
      onClick: () => setOpenShareModal(true)
    },
    {
      id: "6",
      backgroundColor: primary_color,
      key: "index",
      imgSrc: indexIcon,
      onClick: () => {
        handleOnClick("/leaflet");
        props.setIndexCarousel(0);
      }
    }
  ];

  const filterFromServer = arrayEl.filter((el) => {
    return arrayToFilter.some((f) => {
      return f === el.key || el.key === "index";
    });
  });

  const elementToMap = filterFromServer;

  return (
    <div
      style={{
        transitionDuration: "500s"
      }}
      className={`${isShown ? "d-flex" : "d-none"} d-lg-none`}
      ref={ref}
      onClick={() => props.setStatusOfMenu(!props.floatingMenu)}
    >
      {elementToMap.length > 1 && (
        <FloatingButton
          backgroundColor={isShown ? primary_color : secondary_color}
          size={45}
          right={false}
        >
          {elementToMap.map((el) => (
            <Item
              key={el.id}
              backgroundColor={el.backgroundColor}
              imgSrc={el.imgSrc}
              onClick={el.onClick}
            />
          ))}
        </FloatingButton>
      )}

      <ShareModal open={openShareModal} setOpen={setOpenShareModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    floatingMenu: state.floatingMenu.isOpen
  };
};

const mapDispatchToProps = { setStatusOfMenu, setIndexCarousel };

export default connect(mapStateToProps, mapDispatchToProps)(FloatingMenu);
