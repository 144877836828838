import React, { Component } from "react";

import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../redux/carousel/action";
import { BREAKPOINT, H_COMPONENTS } from "../../helper/constant";
import { isMobileSafari } from "react-device-detect";

var isMobile = window.innerWidth < BREAKPOINT.lg;

class CustomIndex extends Component {
  render() {
    const listLink = this.props.index.links.map((element, key) => {
      if (element.type === "url") {
        return (
          <CustomUrl
            key={key}
            page={element.page}
            blueprint={element.blueprint}
            url={element.url}
            target={element.target}
          />
        );
      } else {
        return (
          <CustomLink
            key={key}
            page={element.page}
            blueprint={element.blueprint}
            setIndexCarousel={this.props.setIndexCarousel}
            goToPage={this.props.goToPage}
          />
        );
      }
    });

    const safariDetectMobile = isMobileSafari ? 30 : 0;

    return (
      <div className="d-flex p-0 flex-column justify-content-center align-items-center bordo">
        <div style={{ position: "relative" }}>
        <div className={`shadow-${this.props.shadow}`}></div>
          <img
            alt=""
            src={
              process.env.PUBLIC_URL +
              this.props.index.image_file +
              "?v=" +
              serverResponse.config.version
            }
            className="img-fluid"
            style={{
              maxHeight: serverResponse.config.adsense
                ? `calc(100vh - ${H_COMPONENTS.header +
                    H_COMPONENTS.adsense +
                    H_COMPONENTS.pager +
                    safariDetectMobile}px)`
                : `calc(100vh - ${H_COMPONENTS.header +
                    H_COMPONENTS.pager +
                    safariDetectMobile}px)`
            }}
          />
          {listLink}
        </div>
      </div>
    );
  }
}

export default connect(null, { setIndexCarousel })(CustomIndex);

const getIndex = (index) => {
  let p = 0;
  if (isMobile || serverResponse.config.show_one_page) {
    if (serverResponse.leaflet.index === null) {
      p = index - 1;
    } else {
      p = index;
    }
  } else {
    if (serverResponse.leaflet.index === null) {
      p = parseInt(index / 2, 10);
    } else {
      p = parseInt(index / 2, 10);
    }
  }
  return p;
};

const CustomLink = (props) => {
  const styleProduct = {
    position: "absolute",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    top: props.blueprint.top + "%",
    left: props.blueprint.left + "%",
    width: props.blueprint.width + "%",
    height: props.blueprint.height + "%"
  };

  let page =
    !isMobile && props.page % 2 !== 0 && !serverResponse.config.show_one_page
      ? props.page - 1
      : props.page;
  return (
    <div
      className="customLink"
      style={styleProduct}
      onClick={() => {
        props.setIndexCarousel(getIndex(page));
      }}
    ></div>
  );
};

const CustomUrl = (props) => {
  const styleProduct = {
    position: "absolute",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    top: props.blueprint.top + "%",
    left: props.blueprint.left + "%",
    width: props.blueprint.width + "%",
    height: props.blueprint.height + "%"
  };

  return (
    <div
      className="customLink"
      style={styleProduct}
      onClick={() => {
        if (props.target === undefined) window.open(props.url, "_self");
        else window.open(props.url, "_blank");
      }}
    ></div>
  );
};
