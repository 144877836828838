var getUrl = window.location;
var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" ;

var leaflets = null
var request = new XMLHttpRequest();
request.open("GET", baseUrl + "getLeaflet.php", false);
request.send(null)
var json_leaflet = JSON.parse(request.responseText);
leaflets = json_leaflet;

const lefletSwitch = () => {
    return leaflets.client;
}

export const serverResponse = lefletSwitch();