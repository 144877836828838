import React, { Component } from "react";
import Logo from "../../../assets/images/icons/carousel.svg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as MobileCarousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import actions from "../../../redux/groceryList/action";
import "../../../assets/css/custom.css";
import Facebook from "../../../assets/images/icons/facebook.svg";
import Whatsapp from "../../../assets/images/icons/whatsapp.svg";
import { serverResponse } from "../../../util/fakeServer";
import "react-alice-carousel/lib/alice-carousel.css";
import { isIOS } from "react-device-detect";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../../settings/gaConfig";
import Adsense from "../Adsense";
import { isModalOpen } from "../../../redux/carousel/action";
import { defaultLanguage, gAInitializer } from "../../../helper/utility";
import { language } from "../../../languageProvider/language/language";

const productUrl = serverResponse.config.shareFlyerURL;

class CarouselMarker extends Component {
  setModal = (swipe) => {
    this.props.setSwipe(swipe);
    this.setState({
      modal: !this.state.modal,
      quantity: this.props.info.quantity_step
        ? this.props.info.quantity_step
        : 1,
      varietySelected: this.props.info.varieties
        ? this.props.info.varieties[0]
        : ""
    });
  };

  state = {
    modal: false
  };

  componentDidMount() {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
  }

  shareOnFacebook = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_facebook,
        label: String(this.props.info.item_id)
      });
    }

    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  shareOnWhatsapp = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_whatsapp,
        label: String(this.props.info.item_id)
      });
    }

    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  render() {
    const listImages = this.props.info.markers[0].data.images.map(
      (element, key) => (
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `${serverResponse.config.clientIcon}`;
          }}
          key={key}
          src={
            process.env.PUBLIC_URL +
            element +
            "?v=" +
            serverResponse.config.version
          }
          alt="img"
          style={{ maxWidth: "350px" }}
        />
      )
    );

    var closeBtn = (
      <Button
        color="secondary"
        onClick={() => {
          if (this.props.zoom !== undefined) {
            this.props.zoom.reset();
          }
          this.setModal(true);
        }}
      >
        <i className="fas fa-arrow-left"></i>
        <strong> {language[defaultLanguage].indietro}</strong>
      </Button>
    );

    return (
      <div
        className={"marker " + serverResponse.config.iconAnimation}
        type="plus"
      >
        <img
          id={"carousel-" + this.props.info.item_id}
          src={Logo}
          className={"icon-marker-large"}
          alt="Plus"
          onClick={() => {
            this.setModal(false);
            if (serverResponse.config.ga_active) {
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.open_product,
                label: String(this.props.info.item_id)
              });
            }
          }}
        />

        <Modal
          id="ModalDetail"
          style={{
            height: "95vh"
          }}
          isOpen={this.state.modal}
          toggle={() => this.setModal(true)}
          size="xl"
          onOpened={() => {
            this.props.isModalOpen(true);
            if (this.props.disableSwipe !== undefined)
              this.props.disableSwipe();
          }}
          onClosed={() => {
            this.props.isModalOpen(false);
            if (this.props.enableSwipe !== undefined) this.props.enableSwipe();
          }}
        >
          <ModalHeader toggle={() => this.setModal(true)} close={closeBtn}>
            {language[defaultLanguage].carosello}
          </ModalHeader>

          <ModalBody>
            <Row style={{ marginBottom: "16px" }}>
              <Col
                style={
                  {
                    /*display: "flex"*/
                  }
                }
              >
                <div className="markers-container" style={{ width: "100%" }}>
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Facebook}
                      className="share-icon"
                      style={{ marginLeft: "auto" }}
                      alt="facebook"
                      onClick={() => this.shareOnFacebook()}
                    />
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      onClick={() => this.shareOnWhatsapp()}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                id="modalCarousel"
                style={{ marginBottom: "10px", width: "100%" }}
              >
                <MobileCarousel
                  swipeable={true}
                  emulateTouch={true}
                  infiniteLoop={true}
                  showStatus={false}
                >
                  {listImages}
                </MobileCarousel>
              </Col>
            </Row>
            <Row></Row>
          </ModalBody>

          <ModalFooter>
            {serverResponse.config.adsense && <Adsense></Adsense>}
            <Button
              style={{ zIndex: "9999" }}
              color="primary"
              onClick={() => {
                if (this.props.zoom !== undefined) {
                  this.props.zoom.reset();
                }
                this.setModal(true);
              }}
            >
              {language[defaultLanguage].chiudi}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    isModalOpen: (bool) => {
      dispatch(isModalOpen(bool));
    }
  };
}

export default connect(null, mapDispatchToProps)(CarouselMarker);
