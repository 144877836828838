import React, { Component } from "react";
import { styles } from "./relatedProduct.style";
import { serverResponse } from "../../util/fakeServer";
import { isMobile } from "react-device-detect";
import { getCustomActions } from "../../helper/utility";

class RelatedProduct extends Component {
  state = {
    tooltipIsOpen: false,
    longText: false
  };

  toggleTooltip = () => {
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen,
      longText: false
    });
  };

  render() {
    return (
      <div
        style={styles.wrapper}
        className={
          !isMobile && this.props.colType && getCustomActions().classColumn
            ? "col-3 col-md-2 text-center"
            : ""
        }
      >
        {this.state.tooltipIsOpen && !isMobile && (
          <div
            onClick={() => {
              this.setState({ longText: !this.state.longText });
            }}
            style={styles.tooltip(serverResponse.config.primary_color)}
          ></div>
        )}
        {this.props.prod.varieties.length > 0 && (
          <p
            style={styles.varieties}
            onClick={() => this.props.handleClick(this.props.prod.item_id)}
          >
            {this.props.prod.varieties[0].length > 15
              ? `${this.props.prod.varieties[0].substring(0, 15)}...`
              : this.props.prod.varieties[0].substring(0, 15)}
          </p>
        )}

        {this.props.prod.images.length > 0 ? (
          <img
            onClick={() => {
              this.props.handleClick(this.props.prod.item_id);
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${serverResponse.config.clientIcon}`;
            }}
            id={"related_" + this.props.prod.item_id}
            src={process.env.PUBLIC_URL + this.props.prod.images[0].image_file}
            alt="img"
            className="img-fluid mt-3"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img
            onClick={() => {
              this.props.handleClick(this.props.prod.item_id);
            }}
            id={"related_" + this.props.prod.item_id}
            src={serverResponse.config.clientIcon}
            alt="img"
            className="img-fluid mt-3"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    );
    // return (
    //     <div style={styles.wrapper} className="col-3 col-md-2 text-center"  onMouseEnter={() => this.toggleTooltip()} onMouseLeave={() => this.toggleTooltip()}>
    //         {this.state.tooltipIsOpen && !isMobile &&<div onClick={()=>{
    //             this.setState({longText : !this.state.longText})
    //                 }} style={styles.tooltip(serverResponse.config.primary_color)}>

    //             <p>{!this.state.longText ? `${this.props.prod.descrizione_estesa.substring(0, 5)}... Leggi tutto ▼` : `${this.props.prod.descrizione_estesa} Chiudi ▲`}</p>
    //         </div>}
    //         {this.props.prod.varieties.length > 0 && <p style={styles.varieties}>{this.props.prod.varieties[0].length > 15 ? `${this.props.prod.varieties[0].substring(0,15)}...` :this.props.prod.varieties[0].substring(0,15)}</p>}

    //         {this.props.prod.images.length > 0
    //             ?
    //             <img onClick={() => {
    //                 this.props.handleClick(this.props.prod.item_id)
    //                 }} id={"related_" + this.props.prod.item_id} src={process.env.PUBLIC_URL + this.props.prod.images[0].image_file} alt="img" className="img-fluid mt-3" style={{ cursor: "pointer" }} />
    //             :
    //                 <img onClick={() => {
    //                 this.props.handleClick(this.props.prod.item_id)
    //                 }} id={"related_" + this.props.prod.item_id} src={serverResponse.config.clientIcon} alt="img" className="img-fluid mt-3" style={{ cursor: "pointer" }} />}

    //     </div>
    // );
  }
}

export default RelatedProduct;
