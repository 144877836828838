import React from "react"
import ProductIndex from './ProductIndex';
import { getCustomActions } from "../../helper/utility"

const Product = (props) => {
  const type = 'Product' + getCustomActions().component;
  const ComponentToRender = ProductIndex[type];
  return <ComponentToRender elementIndex={props.elementIndex} info={props.info} />
}

export default Product