import { serverResponse } from "../fakeServer";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../settings/gaConfig";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";

const sidebar_btns = {
  leaflet: {
    name: language[defaultLanguage].volantino,
    routepath: "/leaflet",
    hoverName: "Volantino",
    iconClass: "fas fa-book-open",
    bgColor: serverResponse.config.tertiary_color,
    element: (el) => document.getElementById(el),
    action: (e) => {}
  },
  catalog: {
    name: language[defaultLanguage].catalogo,
    routepath: "/leaflet",
    hoverName: "Catalogo",
    iconClass: "fas fa-book-open",
    bgColor: serverResponse.config.tertiary_color,
    element: (el) => document.getElementById(el),
    action: (e) => {}
  },
  menu: {
    name: language[defaultLanguage].menu,
    routepath: "/leaflet",
    hoverName: "Menù",
    iconClass: "fas fa-book-open",
    bgColor: serverResponse.config.tertiary_color,
    element: (el) => document.getElementById(el),
    action: (e) => {}
  },
  pages: {
    name: language[defaultLanguage].pagine,
    routepath: "/pages",
    hoverName: "Pagine",
    iconClass: "fas fa-columns",
    bgColor: serverResponse.config.tertiary_color,
    element: (el) => document.getElementById(el),
    action: (e) => {}
  },
  pdf: {
    name: language[defaultLanguage].pdf,
    routepath: "/Pdf",
    hoverName: "Scarica Pdf",
    iconClass: "fas fa-file-pdf",
    bgColor: serverResponse.config.tertiary_color,
    element: (el) => document.getElementById(el),
    action: (e) => {
      e.preventDefault();
      if (serverResponse.config.ga_active) {
        ReactGA.event({
          category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
          action: gaEventLabel.open_pdf,
          label: "PDF"
        });
      }
      if (window.ReactNativeWebView !== undefined) {
        var obj = {
          action: "download",
          value:
            "https://" +
            window.location.host +
            "/" +
            serverResponse.config.pdfPath
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(obj));
      } else {
        window.open(
          process.env.PUBLIC_URL + serverResponse.config.pdfPath,
          "_blank"
        );
      }
    }
  },
  products: {
    name: language[defaultLanguage].prodotti,
    routepath: "/categories",
    hoverName: "Prodotti",
    iconClass: isMobile ? "fas fa-search" : "fas fa-list-ul",
    bgColor: serverResponse.config.tertiary_color,
    element: (el) => document.getElementById(el),
    action: (e) => {}
  },
  full_screen: {
    name: language[defaultLanguage].schermo_intero,
    id: "fullScreenId",
    hoverName: "Schermo Intero",
    routepath: "/fullScreen",
    iconClass: "fas fa-arrows-alt",
    element: (el) => document.getElementById(el),
    bgColor: serverResponse.config.tertiary_color,
    action: (e) => {
      e.preventDefault();
      //var routeHeight =document.getElementById("root").firstChild;
      var routeHeight = window.document.documentElement;
      if (routeHeight.requestFullscreen) {
        routeHeight.requestFullscreen();
      } else if (routeHeight.mozRequestFullScreen) {
        /* Firefox */
        routeHeight.mozRequestFullScreen();
      } else if (routeHeight.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        routeHeight.webkitRequestFullscreen();
      } else if (routeHeight.msRequestFullscreen) {
        /* IE/Edge */
        routeHeight.msRequestFullscreen();
      }
    }
  }
};

var sidebarData = [];
// sidebarData.push(sidebar_btns["catalog"]);

serverResponse.config.sidebar.map((btn) => {
  if (btn !== "share") {
    sidebarData.push(sidebar_btns[btn]);
  }
  return 0;
});
if (
  serverResponse.config.only_browsable ||
  serverResponse.config.hide_product_page
) {
  var removed = false;
  var i = 0;
  while (!removed && i < sidebarData.length) {
    if (sidebarData[i].name === "sidebar.categories") {
      sidebarData.splice(i, 1);
      removed = true;
    }
    i++;
  }
}

export { sidebarData };
